import { ShoppingCartStatus, StorageKey, StorePersistService } from './store-persist.service';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { create as createProductList, update as updateProductList } from '@app/api/action/ProductList';
import { RootReducer, Store } from '@app/app.reducers';
import {
	getCartItems,
	getIsCxmlSession,
	getIsPersistedStateLoaded,
	setCartItems,
} from '@app/checkout/modules/checkout-shared/reducers/checkout.reducer';
import {
	ProductListDto,
	ProductListWithProducts,
	cartItemsEqual,
	getMyProducts,
	getPersistedShoppingCart,
	hasFetchedLists,
	setInitialized,
} from '@app/checkout/modules/checkout-shared/reducers/persisted-list.reducer';
import {
	filterTruthy,
	firstTruthy,
	getDebug,
	isArrayEqual,
	omit,
	once,
	pluck,
} from '@app/shared/utils/util';
import { getIsAnonUser, getUser } from '@app/user/reducers/user.reducer';
import { pluckSuccessData } from '@granodigital/grano-remote-data';
import { BehaviorSubject, Observable, combineLatest, firstValueFrom, identity, merge } from 'rxjs';
import {
	distinctUntilChanged,
	filter,
	first,
	map,
	skip,
	switchMap,
	takeUntil,
	withLatestFrom,
} from 'rxjs/operators';

type LocalStorageItem = Override<
	Omit<api.CartItemWithProductDto, 'price'>,
	{
		product: Pick<api.CartItemProductDto, 'id' | 'updated_at'> & {
			locale: Pick<api.ProductLocaleDto, 'product_name'>;
			images?: Pick<api.ProductImagesDto, 'master'>;
		};
	}
>;

/** Service to persist and sync product lists between local storage and the server. */
@Injectable({ providedIn: 'root' })
export class ProductListPersistService {
	private readonly debug = getDebug('ProductListPersistService');
	/** Start the service. */
	// eslint-disable-next-line unicorn/consistent-function-scoping
	init = once(() => this.startSyncOnUserLogin());

	private shoppingCartStatusInitialized = false;
	private readonly shoppingCartStatus$ = new BehaviorSubject<ShoppingCartStatus[]>([]);

	constructor(
		private readonly store: Store<RootReducer.State>,
		private readonly storePersist: StorePersistService,
		private readonly route: ActivatedRoute,
	) {}

	// If cXML is being used, then do not activate the persist service.
	private readonly isCxml$ = this.route.queryParamMap.pipe(
		withLatestFrom(this.store.select(getIsCxmlSession)),
		map(([qp, isCxmlSession]) => !!qp.get('punchout') || isCxmlSession),
	);

	/** Is user logged in and not in a cxml session? */
	private readonly activeUserId$ = this.store.select(getUser).pipe(
		withLatestFrom(this.isCxml$),
		map(([user, isCxml]) => (isCxml ? undefined : user?.id)),
	);

	/**
	 * Converts the list to be sent to the API by removing unnecessary product data.
	 */
	convertListForApi(list: ProductListWithProducts | ProductListDto): ProductListDto {
		const products = (list.products || []).map((item) => ({
			...item,
			product: {
				id: item.product.id,
				created_at: item.product.created_at,
				updated_at: item.product.updated_at,
				locale: { product_name: item.product?.locale?.product_name },
				images: { master: item.product?.images?.master },
			},
		}));
		return { ...list, products };
	}

	/**
	 * Once user logs in, sync local storage and server state and start updating server state.
	 */
	private startSyncOnUserLogin() {
		// mark lists ready for anonymous users once local storage is loaded.
		combineLatest([this.store.select(getIsAnonUser), this.store.select(getIsPersistedStateLoaded)])
			.pipe(first((conditions) => conditions.every(Boolean)))
			.subscribe(() => {
				this.debug('marking lists ready for anonymous user');
				this.store.dispatch(setInitialized(true));
			});

		this.activeUserId$
			.pipe(
				filterTruthy,
				switchMap(() =>
					combineLatest([
						this.store.select(hasFetchedLists),
						this.store.select(getIsPersistedStateLoaded),
					]).pipe(first(([listsFetched, stateLoaded]) => listsFetched && stateLoaded)),
				),
				// Get the initial server state.
				switchMap(() =>
					combineLatest([
						this.store.select(getPersistedShoppingCart),
						this.store.select(getMyProducts).pipe(pluckSuccessData),
					]).pipe(first()),
				),
				// Merge it's content with the local storage state.
				switchMap(async ([cart, myProducts]) => {
					await this.mergeLocalStorageAndServerState(cart);
					return [cart, myProducts];
				}),
				// Start monitoring product list updates.
				switchMap(([cart, myProducts]) => {
					// Mark lists ready
					this.store.dispatch(setInitialized(true));
					return merge(
						this.getListUpdates(this.getLatestCart$(), Number.isFinite(cart?.id)),
						this.getListUpdates(
							this.store.select(getMyProducts).pipe(pluckSuccessData),
							Number.isFinite(myProducts?.id),
						),
					).pipe(
						// Stop cart updates when user logs out.
						takeUntil(this.activeUserId$.pipe(first((id) => !id))),
						this.debug.observe('product-list-updates'),
					);
				}),
			)
			.subscribe((list) => {
				if (list.id) this.store.dispatch(updateProductList(list.id, { body: list }));
				else this.store.dispatch(createProductList({ body: list }));
			});
	}

	/** Combines persisted shopping cart and redux cart contents. */
	private readonly getLatestCart$ = () =>
		combineLatest([
			this.store.select(getPersistedShoppingCart),
			this.store
				.select(getCartItems)
				.pipe(map((products) => products.map((product) => omit(product, ['price'])))),
		]).pipe(map(([persisted, products]) => persisted && { ...persisted, products }));

	/**
	 * Merges the local storage and server state of the shopping cart.
	 * @param cart The server state of the shopping cart.
	 * @returns A promise that resolves when the merging is complete.
	 */
	private async mergeLocalStorageAndServerState(cart?: ProductListWithProducts): Promise<void> {
		this.debug('merging cart state from server:', cart);

		// Ignore list updates when handling a punchout cart.
		if (this.route.snapshot.queryParamMap.get('punchout')) {
			this.debug('using punchout cart');
			return;
		}

		this.initShoppingCartStatus();
		const localStorageItems: LocalStorageItem[] =
			this.storePersist.getStoreState(StorageKey.Checkout)?.items || [];
		const cartItems = cart?.products ?? [];

		// Local storage is empty, use API cart as-is.
		if (cart && cartItems.length > 0 && localStorageItems.length === 0) {
			this.debug('using API cart', { cart });
			this.store.dispatch(setCartItems(cart.products));
			return;
		}

		if (!cart?.id) {
			this.debug('using local storage cart', { localStorageItems });
			return;
		}

		const persistedAt = cart?.updated_at;
		const parsedItems = await this.parseShoppingCartItems(cartItems, localStorageItems, persistedAt);

		this.debug('updating api cart and fetching product information', { parsedItems });
		// Update the merged cart state to the API and get back the product details.
		this.store.dispatch(
			updateProductList(cart.id, { body: this.convertListForApi({ ...cart, products: parsedItems }) }),
		);

		// Update checkout state to reflect the new state and fresh product data.
		const cartWithProducts = await firstValueFrom(
			this.store.select(getPersistedShoppingCart).pipe(skip(1), firstTruthy),
		);

		if (cartWithProducts) this.store.dispatch(setCartItems(cartWithProducts.products));
	}

	/**
	 * Get the latest list state and start monitoring for updates.
	 */
	private getListUpdates(
		list$: Observable<ProductListWithProducts | undefined>,
		hasInitialList: boolean,
	) {
		return list$.pipe(
			withLatestFrom(this.activeUserId$),
			filter(
				(
					input: [ProductListWithProducts | undefined, string | undefined],
				): input is [ProductListWithProducts, string] => {
					const [list, activeUserId] = input;
					return (
						!!activeUserId &&
						!!list &&
						// Do not create a new empty list, only create if it has at least one product.
						(Number.isFinite(list.id) || list.products?.length > 0)
					);
				},
			),
			map(([list]) => this.convertListForApi(list)),
			distinctUntilChanged((list1, list2) =>
				isArrayEqual(list1?.products, list2?.products, cartItemsEqual),
			),
			hasInitialList ? skip(1) : identity, // Ignore the initial value we get from the API.
		);
	}

	/** TODO: Refactor */
	private initShoppingCartStatus() {
		if (this.shoppingCartStatusInitialized) return;
		this.shoppingCartStatusInitialized = true;
		this.activeUserId$.subscribe((activeUserId) => {
			// Combine shopping cart status for current user and anonymous user
			const cartStatusForUser = this.storePersist.getShoppingCartStatus(activeUserId);
			const cartStatusForAnon = this.storePersist.getShoppingCartStatus(undefined);
			const cartStatusForUserIds = new Set(cartStatusForUser.map((item) => item.productUuid));
			this.shoppingCartStatus$.next([
				...cartStatusForUser,
				...cartStatusForAnon.filter((item) => !cartStatusForUserIds.has(item.productUuid)),
			]);
		});
	}

	/** Parse and merge persisted and local storage cart items. */
	private async parseShoppingCartItems(
		persistedItems: api.CartItemWithProductDto[],
		localStorageItems: LocalStorageItem[],
		persistedAt?: string,
	): Promise<LocalStorageItem[]> {
		const listStatus = this.shoppingCartStatus$.value;
		this.debug('merging cart items', { persistedItems, localStorageItems, listStatus });
		const cartItemIds = new Set(persistedItems.map(pluck('id')));
		const missingItemIds =
			listStatus?.length > 0
				? new Set(
						localStorageItems
							.filter((item) => {
								if (cartItemIds.has(item.id)) return false;
								const itemStatus = listStatus?.find((status) => status.productUuid === item.id);
								if (!itemStatus) return false;
								const persistedAtDate = new Date(persistedAt || 0);
								const addedToListDate = new Date(itemStatus?.lastUpdated || 0);
								return !itemStatus?.isPersisted && addedToListDate > persistedAtDate;
							})
							.map((item) => item.id),
					)
				: new Set(localStorageItems.filter((item) => !cartItemIds.has(item.id)).map((item) => item.id));
		if (missingItemIds.size > 0) this.debug('adding items missing from API', missingItemIds);
		const combinedItems = [
			...persistedItems,
			...localStorageItems.filter((item) => missingItemIds.has(item.id)),
		];
		await this.updateShoppingCartStatus(combinedItems);
		return combinedItems;
	}

	/** TODO: Refactor */
	private async updateShoppingCartStatus(
		cartItems: (api.CartItemWithProductDto | LocalStorageItem)[],
	): Promise<ShoppingCartStatus[]> {
		const activeUserId = await firstValueFrom(this.activeUserId$);
		const listState: ShoppingCartStatus[] = cartItems.map((item) => ({
			productUuid: item.id,
			lastUpdated: new Date().toISOString(),
			isPersisted: true,
		}));
		// Set cart status and unset anonymous user cart status
		this.storePersist.setShoppingCartStatus(undefined, []);
		this.storePersist.setShoppingCartStatus(activeUserId, listState);
		return listState;
	}
}
