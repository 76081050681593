import { ResponsiveImage } from '../directives/responsive-image.directive';
import { BASE_URL } from '@app/api/action/Store';
import { RootReducer } from '@app/app.reducers';
import {
	ContentResponse,
	CustomHtmlBlockContent,
	FooterContent,
	HomepageSeoContent,
	MainMenuContent,
	NotificationBannerContent,
	VignetteContent,
} from '@app/core/reducers/content.reducer';
import { setName } from '@app/shared/utils/name-helper';
import {
	ApiAction,
	ApiError,
	ApiState,
	Failure,
	foldSuccess,
	handleApiAction,
	Initialized,
	isFailure,
} from '@granodigital/grano-remote-data';
import { createSelector } from 'reselect';

interface ContentNotFoundResponse {
	message: string;
}

export interface StoreWithDetails extends api.StoreDto {
	id: number;
	customer_id: number;
	use_shared_auth: boolean;
	cas_customer_slug: string;
	content?: {
		vignette: ContentResponse<VignetteContent> | ContentNotFoundResponse;
		mainmenu: ContentResponse<MainMenuContent> | ContentNotFoundResponse;
		footer: ContentResponse<FooterContent> | ContentNotFoundResponse;
		'custom-html': ContentResponse<CustomHtmlBlockContent> | ContentNotFoundResponse;
		'notification-banner': ContentResponse<NotificationBannerContent> | ContentNotFoundResponse;
		'homepage-seo': ContentResponse<HomepageSeoContent> | ContentNotFoundResponse;
	};
}

export interface State {
	store: ApiState<StoreWithDetails>;
	punchOutErrorCode?: string;
}

export interface StoreColors {
	primary?: string;
	primaryText?: string;
}

export const initialState: State = {
	store: new Initialized(),
};

export const RECEIVE_PUNCHOUT_LOGIN_ERROR = 'mygrano/storefront/RECEIVE_PUNCHOUT_LOGIN_ERROR';
export const receivePunchoutLoginError = (code: string) =>
	({ type: RECEIVE_PUNCHOUT_LOGIN_ERROR, code }) as const;

type StorefrontAction =
	| ApiAction<typeof BASE_URL, StoreWithDetails>
	| ReturnType<typeof receivePunchoutLoginError>;

export function reducer(state: State = initialState, action: StorefrontAction): State {
	switch (action.type) {
		case BASE_URL:
			return { ...state, store: handleApiAction(action) };
		case RECEIVE_PUNCHOUT_LOGIN_ERROR:
			return {
				...state,
				store: new Failure<ApiError>({ message: 'Punchout auth failed' }),
				punchOutErrorCode: action.code,
			};
		default:
			return state;
	}
}

const getState = setName('getState', (state: RootReducer.State) => state.storefront);

/** @deprecated Use StoreDataService */
export const getStore = setName(
	'getStore',
	createSelector(getState, (state) => state.store),
);
/** @deprecated Use StoreDataService */
export const getStorefront = setName(
	'getStorefront',
	createSelector(getState, (state) => foldSuccess(state.store)),
);
/** @deprecated Use getStore RemoteData instead */
export const getStorefrontErrorCode = setName(
	'getStorefrontErrorCode',
	createSelector(getState, (state) => isFailure(state.store) && state.store.error.status),
);
/** @deprecated Use StoreDataService */
export const getHomePage = setName(
	'getHomePage',
	createSelector(getStorefront, (store) => store?.storefront?.homepage),
);
/** @deprecated Use StoreDataService */
export const getId = setName(
	'getId',
	createSelector(getStorefront, (store) => store?.id),
);
/** @deprecated Use StoreDataService */
export const getImages = setName(
	'getImages',
	createSelector(getStorefront, (store) => store?.images),
);
/** @deprecated Use StoreDataService */
export const getPrimaryColor = setName(
	'getPrimaryColor',
	createSelector(getStorefront, (store) => store?.storefront?.primary_color),
);
/** @deprecated Use StoreDataService */
export const getStoreColors = setName(
	'getStoreColors',
	createSelector(
		getStorefront,
		(store): StoreColors => ({
			primary: store?.storefront?.primary_color,
			primaryText: store?.storefront?.primary_text_color,
		}),
	),
);

/** @deprecated Use StoreDataService */
export const getLogoUrl = setName(
	'getLogoUrl',
	createSelector(getImages, (images): ResponsiveImage | undefined =>
		images
			? {
					src: images.logo,
					srcSet: `${images.logo}, ${images.logo300} 2x, ${images.logo600} 3x`,
				}
			: undefined,
	),
);

/** @deprecated Use StoreDataService */
export const getMobileLogoUrl = setName(
	'getMobileLogoUrl',
	createSelector(getImages, (images): ResponsiveImage | undefined =>
		images
			? {
					src: images.mobileLogo,
					srcSet: `${images.mobileLogo}, ${images.mobileLogo128} 2x, ${images.mobileLogo256} 3x`,
				}
			: undefined,
	),
);

// TODO: Refactor.
export const getPunchOutErrorCode = setName(
	'getPunchOutErrorCode',
	createSelector(getState, (state) => state.punchOutErrorCode),
);

/** @deprecated Use StoreDataService */
export const getIsCxmlOrderRequestHandlingEnabled = setName(
	'getIsCxmlOrderRequestHandlingEnabled',
	createSelector(getStorefront, (store) => store?.storefront?.enable_cxml_order_request_handling),
);

/** @deprecated Use StoreDataService */
export const getIsCxmlOrderShippingAddressEnabled = setName(
	'getIsCxmlOrderShippingAddressEnabled',
	createSelector(getStorefront, (store) => store?.storefront?.enable_cxml_order_shipping_address),
);
