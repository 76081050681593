import * as ApiPrice from '@app/api/action/Price';
import * as ApiProduct from '@app/api/action/Product';
import { Pagination, RootReducer } from '@app/app.reducers';
import { isApiActionSuccess } from '@app/shared/utils/api-utils';
import { setName } from '@app/shared/utils/name-helper';
import { transformSlugs } from '@app/shared/utils/util';
import { ApiAction } from '@granodigital/grano-remote-data';
import { createSelector } from 'reselect';

export const hasFetchedPrice = (priceState: ProductPriceRequest): boolean =>
	!!priceState && !priceState.isFetching;

//#region Types

export enum ProductType {
	Variable = 'variable',
	PrintOnDemand = 'print-on-demand',
	Stock = 'stock',
	Form = 'form',
	Editable = 'editable',
	MultipleOption = 'multiple-option',
}

export type ProductMediaItem = api.ProductImageDto | string;
export type SelectedOptions = api.OrderProductSelectedOptionsDto;

export interface SelectedOptionsEvent {
	isValid: boolean;
	selectedOptions: SelectedOptions;
	errors?: Record<string, string>;
}

export interface ProductPriceRequest {
	id: number;
	price?: api.ProductPriceDto;
	isError: boolean;
	isFetching: boolean;
}

export interface PaginatedProductsEntry {
	products: api.ProductDto[];
	pagination: Pagination;
}

export type PaginatedProducts = Record<string, PaginatedProductsEntry>;

export enum ProductOrderIntegration {
	OrderSync = 'order_synchronizer',
	Email = 'email',
	Download = 'download',
}

export interface State {
	isLoading: boolean;
	isError: boolean;
	productsByIds: Map<number, api.ProductDto>;
	/** @deprecated Use productsByIds instead. */
	productsForOrder: api.ProductDto[];
	paginatedProducts: PaginatedProducts;
	prices: Map<number, ProductPriceRequest>;
}

export const initialState: State = {
	isLoading: true,
	isError: false,
	productsByIds: new Map(),
	productsForOrder: [],
	paginatedProducts: {},
	prices: new Map(),
};

//#endregion Types

const RESET_PRODUCT_STATE = 'mygrano/product/RESET_PRODUCT_STATE';
export const resetProductState = () => ({ type: RESET_PRODUCT_STATE }) as const;

const SET_ERROR = 'mygrano/product/SET_ERROR';
export const setError = () => ({ type: SET_ERROR }) as const;

type ProductAction =
	| ReturnType<typeof resetProductState | typeof setError>
	| ApiAction<typeof ApiProduct.GET_PRODUCTS_BY_CATEGORY_START, never, never>
	| ApiAction<typeof ApiProduct.GET_PRODUCTS_START, never, never>
	| ApiAction<
			typeof ApiProduct.GET_PRODUCTS_BY_CATEGORY,
			ApiProduct.GET_PRODUCTS_BY_CATEGORY,
			{ collectionKey: string }
	  >
	| ApiAction<typeof ApiProduct.GET_PRODUCTS, ApiProduct.GET_PRODUCTS, { collectionKey: string }>
	| ApiAction<typeof ApiProduct.GET_PRODUCTS_BY_ID_LIST_START, never, never>
	| ApiAction<typeof ApiProduct.GET_PRODUCTS_BY_ID_LIST, ApiProduct.GET_PRODUCTS_BY_ID_LIST, never>
	| ApiAction<typeof ApiPrice.GET_PRICE_FOR_PRODUCT_START, never, { productId: number }>
	| ApiAction<
			typeof ApiPrice.GET_PRICE_FOR_PRODUCT,
			ApiPrice.GET_PRICE_FOR_PRODUCT,
			{ productId: number }
	  >;
/** Product reducer */
export function reducer(state: State = initialState, action: ProductAction): State {
	let products: api.ProductDto[];

	switch (action.type) {
		case RESET_PRODUCT_STATE:
			return initialState;

		case SET_ERROR:
			return { ...state, isError: true, isLoading: false };

		case ApiProduct.GET_PRODUCTS_BY_CATEGORY_START:
		case ApiProduct.GET_PRODUCTS_START: {
			return { ...state, isError: false, isLoading: true };
		}

		case ApiProduct.GET_PRODUCTS_BY_CATEGORY:
		case ApiProduct.GET_PRODUCTS: {
			// @ts-expect-error -- TODO: Fix this possible bug!
			products = Array.isArray(action.payload.models) ? [...action.payload.models] : [];
			products = products.map((product) => ({
				...product,
				categories: transformSlugs(product.categories),
			}));
			// @ts-expect-error -- TODO: Fix this possible bug!
			// istanbul ignore if
			if (!isApiActionSuccess(action)) {
				return { ...state, isError: true, isLoading: false };
			}
			// TODO: Some de-duplication of data could be used here. All fetched products could be saved under allProducts key
			// and paginatedProducts could use references there instead of full product values.
			const collectionKey = action?.meta?.info?.collectionKey ?? 'current';
			// TODO: Fix missing types.
			const pagination = (action.payload as any).pagination || {};
			const paginatedProducts: State['paginatedProducts'] = {
				...state.paginatedProducts,
				[collectionKey]: {
					products,
					pagination,
				},
			};

			return {
				...state,
				isLoading: false,
				isError: !!action.error,
				paginatedProducts,
			};
		}
		case ApiProduct.GET_PRODUCTS_BY_ID_LIST_START:
			return { ...state, isError: false, isLoading: true, productsForOrder: [] };
		case ApiProduct.GET_PRODUCTS_BY_ID_LIST: {
			// @ts-expect-error -- TODO: Fix this possible bug!
			products = Array.isArray(action.payload.models) ? [...action.payload.models] : [];
			const existingProductsById = state.productsByIds !== undefined ? [...state.productsByIds] : [];
			const productsByIds = new Map<number, api.ProductDto>([
				...existingProductsById,
				...products.map(
					(product) =>
						[product.id!, { ...product, categories: transformSlugs(product.categories) }] as const,
				),
			]);
			return {
				...state,
				isLoading: false,
				isError: !!action.error,
				productsByIds,
				productsForOrder: [...products],
			};
		}

		case ApiPrice.GET_PRICE_FOR_PRODUCT_START: {
			const id = action?.meta?.info?.productId;
			return {
				...state,
				prices: new Map([...state.prices.entries(), [id, { id, isError: false, isFetching: true }]]),
			};
		}

		case ApiPrice.GET_PRICE_FOR_PRODUCT: {
			const id = action?.meta?.info?.productId;
			return {
				...state,
				prices: new Map([
					...state.prices.entries(),
					[
						id,
						{
							id,
							price: isApiActionSuccess(action) ? action.payload : undefined,
							isError: !!action.error,
							isFetching: false,
						},
					],
				]),
			};
		}
		default:
			return state;
	}
}

export const getState = (state: RootReducer.State) => state.product;

export const getPriceMap = setName(
	'getPriceMap',
	createSelector(getState, (state) => state.prices),
);

export const isLoading = setName(
	'isLoading',
	createSelector(getState, (state) => state.isLoading),
);
export const isError = setName(
	'isError',
	createSelector(getState, (state) => state.isError),
);
export const getProductById = (id: number) =>
	setName(
		'getProductById',
		createSelector(getState, (state) =>
			state.productsByIds !== undefined ? state.productsByIds.get(id) : undefined,
		),
	);

export const getPrice = (productId: number) =>
	setName(
		'getPrice',
		createSelector(getPriceMap, (prices) => prices.get(productId)),
	);

export const getProductsById = (productIds: Set<number>) =>
	setName(
		'getProductsById',
		createSelector(getState, (state) =>
			[...(state.productsByIds ?? [])]
				.filter(([id]) => productIds.has(id))
				.map(([, product]) => product),
		),
	);

export const getAllProductsById = setName(
	'getAllProductsById',
	createSelector(getState, (state) => state.productsByIds),
);

/** @deprecated Use getProductsById instead. */
export const getProductsForOrder = setName(
	'getProductsForOrder',
	createSelector(getState, (state) => state.productsForOrder),
);

export const getPaginatedProducts = setName(
	'getPaginatedProducts',
	createSelector(getState, (state) => state.paginatedProducts),
);

export const getNonFetchedProductIds = (productIds: number[]) =>
	setName(
		'getNonFetchedProductIds',
		createSelector(getState, (state) => {
			if (!Array.isArray(productIds) || productIds.length === 0) return [];
			if (!state.productsByIds) return productIds;
			return productIds.filter((id) => !state.productsByIds.has(id));
		}),
	);
