import { popErrorToast } from '../reducers/toast.reducer';
import { Action, RootReducer, Store } from '@app/app.reducers';
import debug from 'debug';
import { MonoTypeOperatorFunction, Observable, pipe } from 'rxjs';
import { distinctUntilChanged, filter, finalize, first, tap } from 'rxjs/operators';

export const UUID_REGEX = /^([\da-z]{8})-([\da-z]{4})-([\da-z]{4})-([\da-z]{4})-([\da-z]{12})$/;

/**
 * A helper for asserting that an unknown value is an object and has or inherits a property.
 * TypeScript makes it really hard to assert that a generic object has a property.
 */
export function hasProp<X, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
	if (!obj) return false;
	if (Object.prototype.hasOwnProperty.call(obj, prop)) return true;
	const proto: unknown = Object.getPrototypeOf(obj);
	if (!proto) return false;
	return hasProp(proto, prop);
}

/** @see https://lodash.com/docs/4.17.15#range */
export function range(start = 0, end = 0): readonly number[] {
	const arr = Array.from<number>({ length: end - start || 0 });
	for (let i = 0; i < arr.length; i++) arr[i] = i + start;
	return arr;
}

/** @see https://lodash.com/docs/4.17.15#castArray */
export function castArray<T>(val: T | T[]): T[] {
	if (Array.isArray(val)) return val;
	if (val === undefined || val === null) return [];
	return [val];
}

/**
 * Lodash.isEmpty equivalent
 * @deprecated Check based on expected type instead of relying on generic "isEmpty".
 */
export const isEmpty = (val: unknown): boolean => {
	if (
		val === undefined ||
		val === null ||
		(val instanceof Set && val.size === 0) ||
		(val instanceof Map && val.size === 0) ||
		(val as unknown[])?.length === 0
	)
		return true;
	if (typeof val === 'object') {
		const proto = Object.getPrototypeOf(val) as unknown;
		// Plain objects.
		return (proto === null || proto === Object.prototype) && Object.keys(val).length === 0;
	}
	return false;
};

// istanbul ignore next
export const timeout = (ms = 0): Promise<void> =>
	new Promise((resolve) => window.setTimeout(resolve, ms));

/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any */
/** @deprecated Test based on known type */
export const firstNotEmpty: <T extends Observable<unknown>>(val: T) => T = first(
	(val) => !isEmpty(val),
) as any;
export const firstTruthy: <T>(val: Observable<T>) => Observable<Exclude<T, undefined | null>> = first(
	(val) => !!val,
) as any;
export const filterTruthy: <T>(val: Observable<T>) => Observable<Exclude<T, undefined | null>> = filter(
	(val) => !!val,
) as any;
/* eslint-enable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any */

/** Filter out null and undefined values, useful for Array.prototype.filter type narrowing. */
export const filterNil = <T>(item: T): item is Exclude<T, undefined | null> =>
	item !== undefined && item !== null;

export const distinctUntilKeysChanged = <T>(keys: (keyof T)[]): MonoTypeOperatorFunction<T> =>
	distinctUntilChanged<T>((a, b) => keys.every((key) => a[key] === b[key]));

type DebugFn = ReturnType<typeof debug> & {
	/** Observe values & finalize event for observable. */
	observe: <T>(name: string, msg?: string) => MonoTypeOperatorFunction<T>;
};
/** A function to get a debug logger to any class easily. */
export function getDebug(name: string): DebugFn {
	const logger = debug(`ecom:${name}`);

	// Add a method to debug observables easily.
	Object.defineProperty(logger, 'observe', {
		value: (observableName: string, msg = '') => {
			logger.extend(observableName)('observing');
			return pipe(
				tap((val) => logger.extend(observableName)('observed', ...[msg, val].filter(Boolean))),
				finalize(() => logger.extend(observableName)('finalized', msg)),
			);
		},
	});

	return logger as DebugFn;
}

/** Lodash omit TS equivalent. */
export const omit = <T, U extends keyof T>(obj: T, keys: readonly U[]): Omit<T, U> =>
	Object.fromEntries(Object.entries(obj || {}).filter(([k]) => !keys.includes(k as U))) as Omit<T, U>;

/** Lodash pick TS equivalent. */
export const pick = <T, U extends keyof T>(obj: T, keys: readonly U[]): Pick<T, U> =>
	Object.fromEntries(Object.entries(obj || {}).filter(([k]) => keys.includes(k as U))) as Pick<T, U>;

export const constant =
	<T>(val: T) =>
	(..._args: unknown[]): T =>
		val;

/** Lodash groupBy TS equivalent using Map. */
export function groupBy<T, K extends keyof T>(
	input: T[] | Set<T> | Map<unknown, T>,
	key: K,
): Map<T[K], T[]> {
	const arr = Array.isArray(input) ? input : [...input.values()];
	const map = new Map<T[K], T[]>();
	for (const item of arr) {
		const group = item[key];
		const list = map.get(group) ?? [];
		list.push(item);
		map.set(group, list);
	}
	return map;
}

/**
 * A template tag for creating multiline commentable RegExps.
 * @param flags RegExp's flags parameter
 * @returns a new and shiny RegExp instance
 * @example
 * const VAT_NUMBER_REGEXP = regex('i')`
 *    # International format: FI12345678, ESX1234567Y, NL999999999B01, etc.
 *    ^([A-Z]{2}[A-Z0-9]{5,15})$
 *    |
 *    # Finnish format: 1234567-8
 *    ^(\d{7}-\d{1})$
 * `;
 * @example
 * const expression = regex('i')`
 *   # This regex contains escaped spaces and comments inside.
 *   ^[\ \s\#]$
 * `;
 */
export function regex(
	flags?: string,
): (strings: TemplateStringsArray, ...values: (string | RegExp | number)[]) => RegExp {
	const trailingComments = /\s+#.*$/gm;
	const surroundingWhitespace = /^\s+|\s+$/gm;
	const literalNewlines = /[\n\r]/g;
	const escapedSpacesOrHashes = /\\(\s|#)/g;

	return (strings, ...values) => {
		// eslint-disable-next-line unicorn/no-array-reduce
		const sourceString = strings.raw.reduce(
			(pattern, rawString, i) =>
				`${pattern}${rawString}${
					// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
					values[i] instanceof RegExp ? (values[i] as RegExp).source : (values[i]?.toString() ?? '')
				}`,
			'',
		);
		const compiledPattern = sourceString
			.replaceAll(trailingComments, '')
			.replaceAll(surroundingWhitespace, '')
			.replaceAll(literalNewlines, '')
			.replaceAll(escapedSpacesOrHashes, '$1');

		try {
			return new RegExp(compiledPattern, flags);
		} catch (err) {
			if (err instanceof Error && err.name === 'SyntaxError' && sourceString.includes(' #'))
				throw new SyntaxError(`${err.message}\nForgot to escape #?\nSource: ${sourceString}`);
			throw err;
		}
	};
}

export const VAT_NUMBER_REGEXP = regex('i')`
	# International format: FI12345678, ESX1234567Y, NL999999999B01, etc.
	^([A-Z]{2}[A-Z0-9]{5,15})$
	|
	# Finnish format: 1234567-8
	^(\d{7}-\d{1})$
`;

/** @see https://lodash.com/docs/#once */
export function once<T extends (...args: never[]) => unknown>(func: T): T {
	let result: unknown;
	return function (this: unknown, ...args) {
		if (func) {
			result = func.apply(this, args);
			func = undefined as unknown as T; // Free old function for garbage collection.
		}
		return result;
	} as T;
}

/**
 * Truncates a string to a specified length and appends ellipsis if necessary.
 * @param str - The string to truncate.
 * @param length - The maximum length of the truncated string.
 * @returns The truncated string.
 */
export function truncate(str: string, length: number): string {
	if (str.length <= length) return str;
	return str.slice(0, Math.max(0, length - 3)) + '...';
}

/**
 * Tries parsing a string into a JSON object
 */
export function tryParseJson<O = never>(str?: string | null): O | undefined {
	try {
		return JSON.parse(str ?? '') as O;
	} catch {
		return;
	}
}

export const pluck =
	<T extends string>(prop: T) =>
	<U extends { [key in T]: unknown }>(obj: U): U[T] =>
		obj?.[prop];

/**
 * Transforms category slugs from underscores to dashes.
 * @param categories The categories
 * @returns transformed categories
 */
export function transformSlugs(categories: api.CategoryDto[] = []): api.CategoryDto[] {
	return categories.map((category) => ({
		...category,
		slug: category.slug?.replace(/_/g, '-'),
		children: category.children ? transformSlugs(category.children) : undefined,
	}));
}

/**
 * Check if the store allows registration of company accounts
 */
export function isCompanyAccountRegistrationAllowed(store: api.StoreDto): boolean {
	return (
		store.storefront?.allow_company_account_registration ??
		store.storefront?.allow_anonymous_browsing ??
		true
	);
}

/**
 * Remove special characters from filename
 * @param filename Filename to sanitize
 */
export function sanitizeFilename(filename: string): string {
	if (isEmpty(filename)) throw new Error('Cannot sanitize empty filename');
	return filename
		.replaceAll(/[^\s\w.-]+/gi, '')
		.replaceAll(/\s{2,}/g, ' ') // Replace any extra spaces caused by previous replacement
		.trim();
}

/**
 * Sets CSS colors by updating the CSS variables in the document body.
 * @param colors - An object containing key-value pairs of color names and their corresponding values.
 */
export function setCssColors(colors: Record<string, string>): void {
	// Set properties for modern browsers.
	for (const key of Object.keys(colors)) {
		document.body.style.setProperty(`--g-color-${key}`, colors[key]);
	}
}

let idCounter = 0;
export const uniqueId = (prefix = ''): string => `${prefix}${++idCounter}`;

/** @see https://lodash.com/docs/#partition */
export function partition<T>(arr: T[], isValid: (elem: T) => boolean): [T[], T[]] {
	// eslint-disable-next-line unicorn/no-array-reduce, @typescript-eslint/no-unnecessary-type-assertion
	return (arr as unknown[]).reduce(
		([pass, fail], elem) =>
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			(isValid(elem as T) ? [[...pass, elem], fail] : [pass, [...fail, elem]]) as [T[], T[]],
		[[], []],
	) as [T[], T[]];
}

export function isArrayEqual(arr1: Primitive[], arr2: Primitive[]): boolean;
export function isArrayEqual<T>(arr1: T[], arr2: T[], predicate: (a: T, b: T) => boolean): boolean;
/** Compare two arrays, if not primitive values, pass in a predicate e.g. isPlainObjectEqual */
export function isArrayEqual<T>(arr1: T[], arr2: T[], predicate = (a: T, b: T) => a === b): boolean {
	if (arr1 === arr2) return true; // Same reference or primitive.
	return (
		Array.isArray(arr1) &&
		Array.isArray(arr2) &&
		arr1.length === arr2.length &&
		arr1.every((item, index) => predicate(item, arr2[index]))
	);
}

/**
 * Checks if two plain objects are equal, optionally omitting specified keys.
 * @param obj1 - The first object to compare.
 * @param obj2 - The second object to compare.
 * @param omitKeys - The keys to be omitted from the comparison.
 * @returns A boolean indicating whether the objects are equal.
 */
export function isPlainObjectEqual<
	T extends Omit<PlainObject<keyof T>, OmitKeys[number]> & { [key in OmitKeys[number]]?: unknown },
	OmitKeys extends readonly (keyof T)[] = never,
>(obj1?: T, obj2?: T, omitKeys?: OmitKeys): boolean {
	if (obj1 === obj2) return true;
	if (!obj1 || typeof obj1 !== 'object' || !obj2 || typeof obj2 !== 'object') return false;
	if (omitKeys) {
		obj1 = omit(obj1, omitKeys) as T;
		obj2 = omit(obj2, omitKeys) as T;
	}
	const entries1 = Object.entries(obj1) as [keyof T, unknown][];
	const entries2 = Object.entries(obj2) as [keyof T, unknown][];
	if (entries1.length !== entries2.length) return false;
	return entries1.every(([key, value]) => value === obj2[key]);
}

/** Checks whether `obj` is a plain object and not an array, map or any other object */
export function isPlainObject(value: unknown): value is Record<string, unknown> {
	if (!value || typeof value !== 'object') return false;
	const prototype: unknown = Object.getPrototypeOf(value);
	return !prototype || prototype === Object.prototype;
}

/**
 * Recursively checks that subsetObj is contained in fullObj.
 * Works with circular references.
 * @param supersetObj Superset object to check subset against
 * @param subsetObj Subset object to check against superset
 * @param history Used internally to track seen objects and to prevent infinite recursion
 */
export function isObjectContained(
	supersetObj: unknown,
	subsetObj: unknown,
	history = new Map<unknown, Set<unknown>>(),
): boolean {
	// Deal with simple cases first.
	if (subsetObj === supersetObj) return true;

	// Can't be contained if both objects aren't plain objects or arrays.
	// NOTE: Arrays are contained only when the array element order matches.
	if (
		(!Array.isArray(subsetObj) || !Array.isArray(supersetObj)) &&
		(!isPlainObject(subsetObj) || !isPlainObject(supersetObj))
	)
		return false;

	// Initialize history for this comparison.
	if (!history.has(subsetObj)) history.set(subsetObj, new Set());
	const subsetComparedTo = history.get(subsetObj)!;

	// Comparison already in progress or done. The other comparison will return the actual result.
	if (subsetComparedTo.has(supersetObj)) return true;

	const subsetEntries = Object.entries(subsetObj);
	const supersetKeys = Object.keys(supersetObj);

	// Can't be contained if subset has more keys than superset.
	if (subsetEntries.length > supersetKeys.length) return false;

	subsetComparedTo.add(supersetObj);

	// Check that every entry in the subset object is contained in the superset object.
	return subsetEntries.every(([key, subsetValue]) => {
		if (!supersetKeys.includes(key)) return false;
		return isObjectContained((supersetObj as Record<string, unknown>)[key], subsetValue, history);
	});
}

/** Capitalize the first letter of a string. */
export function capitalizeFirstLetter<T extends string>(str: T): Capitalize<T> {
	return (str.charAt(0).toUpperCase() + str.slice(1)) as Capitalize<T>;
}

/** Check if a value is an object. */
const isObject = (value: unknown): boolean =>
	!!value && typeof value === 'object' && !Array.isArray(value);

/** Recursively merge b into a, returns a clone. */
export const defaultsDeep = <T extends Partial<Record<keyof T, unknown>>>(
	target: T,
	...sources: T[]
): T => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	target = target ? structuredClone(target) : ({} as T);
	for (const source of sources)
		(Object.entries(source || {}) as [keyof T, unknown][])
			// Only merge undefined/object values.
			.filter(([key]) => target[key] === undefined || isObject(target[key]))
			// eslint-disable-next-line unicorn/no-array-for-each
			.forEach(
				([key, value]) =>
					(target[key] = (
						isObject(value)
							? defaultsDeep((target[key] || {}) as T, value as T) // Recurse.
							: value
					) as T[keyof T]), // Set default.
			);
	return target;
};

// Extracted from https://github.com/Qix-/color-convert/blob/2.0.0/conversions.js
// and https://github.com/Qix-/color/blob/3.1.1/index.js

/**
 * Converts an RGB tuple to HSL tuple.
 * @param rgb [0-255, 0-255, 0-255]
 * @returns [0-360, 0-100, 0-100]
 */
export function rgbToHsl(rgb: [number, number, number]): [number, number, number] {
	const r = Math.min(1, Math.max(0, rgb[0] / 255));
	const g = Math.min(1, Math.max(0, rgb[1] / 255));
	const b = Math.min(1, Math.max(0, rgb[2] / 255));
	const min = Math.min(r, g, b);
	const max = Math.max(r, g, b);
	const delta = max - min;
	let h = 0;
	let s: number;

	switch (max) {
		case min:
			break;
		case r:
			h = (g - b) / delta;
			break;
		case g:
			h = 2 + (b - r) / delta;
			break;
		case b:
			h = 4 + (r - g) / delta;
			break;
	}

	h = Math.min(h * 60, 360);

	if (h < 0) {
		h += 360;
	}

	const l = (min + max) / 2;

	if (max === min) {
		s = 0;
	} else if (l <= 0.5) {
		s = delta / (max + min);
	} else {
		s = delta / (2 - max - min);
	}

	return [h, s * 100, l * 100];
}

/**
 * Converts an RGB tuple to hex color string.
 * @param args [0-255, 0-255, 0-255]
 * @returns Hex color code
 */
export function rgbToHex(args: [number, number, number]): string {
	if (!args?.length || args.length < 3) return '';
	const clampedArgs = args.map((v) => Math.min(255, Math.max(0, Math.round(v))));
	/* eslint-disable no-bitwise */
	const integer =
		((clampedArgs[0] & 0xff) << 16) + ((clampedArgs[1] & 0xff) << 8) + (clampedArgs[2] & 0xff);
	/* eslint-enable no-bitwise */
	const string = integer.toString(16).toLowerCase();
	return '000000'.slice(string.length) + string;
}

/* spell-checker:words RRGGBB RRGGBBAA */
/**
 * Converts a hex color code to RGB tuple.
 * Accepts RGB, RRGGBB, RRGGBBAA, #RGB, #RRGGBB, #RRGGBBAA.
 *
 * _Note: Alpha channel isn't included in return value._
 * @param hexColor Hex color code
 * @returns [0-255, 0-255, 0-255]
 */
export function hexToRgb(hexColor: string): [number, number, number] {
	if (!hexColor) return [0, 0, 0];
	const match = /^#?(?:([\da-f]{6})([\da-f]{2})?|([\da-f]{3}))$/i.exec(hexColor);
	if (!match) {
		return [0, 0, 0];
	}

	const colorString = match[3] ? [...match[3]].map((char) => char + char).join('') : match[1];

	// TODO: Maybe return alpha as well in #RRGGBBAA?
	// const alphaString = match[2];

	const integer = Number.parseInt(colorString, 16);
	/* eslint-disable no-bitwise */
	const r = (integer >> 16) & 0xff;
	const g = (integer >> 8) & 0xff;
	const b = integer & 0xff;
	/* eslint-enable no-bitwise */

	// No need to clamp converted values
	return [r, g, b];
}

/**
 * Converts an HSL tuple to RGB tuple.
 * @param hsl [0-360, 0-100, 0-100]
 * @returns [0-255, 0-255, 0-255]
 */
export function hslToRgb(hsl: [number, number, number]): [number, number, number] {
	const h = (hsl[0] / 360) % 1; // Hue loops around
	const s = Math.min(1, Math.max(0, hsl[1] / 100));
	const l = Math.min(1, Math.max(0, hsl[2] / 100));
	let t3: number;
	let val: number;

	if (s === 0) {
		val = l * 255;
		return [val, val, val];
	}

	const t2 = l < 0.5 ? l * (1 + s) : l + s - l * s;

	const t1 = 2 * l - t2;

	const rgb: [number, number, number] = [0, 0, 0];
	for (let i = 0; i < 3; i++) {
		t3 = h + (1 / 3) * -(i - 1);
		if (t3 < 0) {
			t3++;
		}

		if (t3 > 1) {
			t3--;
		}

		if (6 * t3 < 1) {
			val = t1 + (t2 - t1) * 6 * t3;
		} else if (2 * t3 < 1) {
			val = t2;
		} else if (3 * t3 < 2) {
			val = t1 + (t2 - t1) * (2 / 3 - t3) * 6;
		} else {
			val = t1;
		}

		rgb[i] = val * 255;
	}

	return rgb;
}

/**
 * Lightens the given color by ratio.
 * e.g. `lightenColor('010101', 2); // 200% lighter = #030303`.
 *
 * _Note: `000000` is treated as `010101` if ratio > 0._
 * @param hex Hex color code
 * @param ratio Ratio to lighten by. Negative to darken.
 * @returns Lightened hex color code
 */
export function lightenColor(hex: string, ratio: number): string {
	// Change #000 to #010101 because 0 * anyRatio === 0.
	// Alpha is ignored, so it can be anything.
	if (ratio > 0 && /^000(000([\da-f]{2})?)?$/i.test(hex)) hex = '010101';

	const hsl = rgbToHsl(hexToRgb(hex));
	hsl[2] += hsl[2] * ratio;
	return rgbToHex(hslToRgb(hsl));
}

/**
 * Darkens the given color by ratio.
 * (alias for `lightenColor` with negated ratio)
 * @see lightenColor
 * @param hex Hex color code
 * @param ratio Ratio to darken by
 * @returns Darkened hex color code
 */
// istanbul ignore next
export function darkenColor(hex: string, ratio: number): string {
	return lightenColor(hex, -ratio);
}

/** Return a getter & setter for a store value. */
export function storeModel<T>(
	store: Store<RootReducer.State>,
	selector: (state: unknown) => T,
	action: (value: T) => Action,
): { get$: Observable<T>; set: (value: T) => void } {
	return {
		get$: store.select(selector),
		set: (value: T) => store.dispatch(action(value)),
	};
}

/** Show a generic error toast. */
export function popGenericErrorToast(store: Store<RootReducer.State>): void {
	store.dispatch(
		popErrorToast({
			title: $localize`:@@ToastGenericError:Something went wrong. Please try again later.`,
		}),
	);
}
