import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RootReducer, Store } from '@app/app.reducers';
import { CognitoService } from '@app/shared/services/cognito.service';
import { NewRelic } from '@app/shared/services/newrelic.service';
import { FormErrors, equalTo, formValidationErrors$, parseCode } from '@app/shared/utils/form-helper';
import { syncPasswordScoreValidation } from '@app/shared/utils/password-score-validation';
import {
	reset,
	setPassword,
	setPasswordRetype,
	setResetToken,
} from '@app/user/reducers/registration.reducer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const MIN_PASSWORD_LENGTH = 10;

/** Reset password component */
@UntilDestroy()
@Component({
	selector: 'g-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
	form!: FormGroup<{
		email: FormControl<string | null>;
		code: FormControl<string | null>;
		password: FormControl<string | null>;
		passwordRetype: FormControl<string | null>;
		passwordScore: FormControl<string | null>;
	}>;
	formErrors$!: FormErrors;
	private readonly validationMessages = {
		email: {
			required: $localize`:@@ContactInfoEmailRequired:Email is required`,
			maxlength: $localize`:@@ContactInfoEmailMaxLength:Email cannot be over 255 characters long`,
			email: $localize`:@@ContactInfoEmailFormat:Email has to be in right format`,
		},
		code: {
			required: $localize`:@@ChangePasswordCodeRequired:Code is required`,
			minlength: $localize`:@@ChangePasswordCodeMinLength:Code must be 6 characters long`,
			maxlength: $localize`:@@ChangePasswordCodeMaxLength:Code must be 6 characters long`,
		},
		password: {
			required: $localize`:@@ChangePasswordPasswordRequired:Password is required`,
			minlength: $localize`:@@ChangePasswordPasswordMinLength:Password must be at least 10 characters long`,
			maxlength: $localize`:@@ChangePasswordPasswordMaxLength:Password cannot be over 255 characters long`,
		},
		passwordRetype: {
			required: $localize`:@@ChangePasswordConfirmPasswordRequired:Confirmation password is required`,
			equalTo: $localize`:@@ChangePasswordConfirmPasswordMatch:Passwords must match`,
		},
		passwordScore: {
			pattern: $localize`:@@ChangePasswordPasswordScoreTooLow:Password is too weak`,
		},
	};

	constructor(
		public router: Router,
		private readonly fb: FormBuilder,
		private readonly store: Store<RootReducer.State>,
		private readonly route: ActivatedRoute,
		private readonly cognito: CognitoService,
		private readonly newRelic: NewRelic,
	) {}

	/** Initialize component */
	ngOnInit(): void {
		this.createForm();
		this.store.dispatch(setResetToken(this.route.snapshot.params.token));
		syncPasswordScoreValidation(this.form, untilDestroyed(this));
	}

	/** Cleanup */
	ngOnDestroy(): void {
		this.store.dispatch(reset());
	}

	/** Submit form */
	async onSubmit(): Promise<void> {
		try {
			await this.cognito.resetPassword(
				this.form.value.email!.toLowerCase(),
				this.form.value.code!,
				this.form.value.password!,
			);
			void this.router.navigate(['/']);
		} catch (err: unknown) {
			this.cognito.handleCognitoException(err);
		}
	}

	/** Set password */
	setPassword(password: string | null): void {
		this.store.dispatch(setPassword(password));
	}

	/** Set password retype */
	setPasswordRetype(password: string | null): void {
		this.store.dispatch(setPasswordRetype(password));
	}

	/** Create form */
	private createForm() {
		this.form = this.fb.group({
			email: ['', [Validators.required, Validators.email, Validators.maxLength(255)]],
			code: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
			password: [
				'',
				[Validators.required, Validators.minLength(MIN_PASSWORD_LENGTH), Validators.maxLength(255)],
			],
			passwordRetype: [''],
			passwordScore: ['', [Validators.pattern(/^[34]$/)]],
		});
		// Set password confirmation validators.
		this.form.controls.passwordRetype.setValidators([
			Validators.required,
			equalTo(this.form.controls.password),
		]);

		// TODO: Do not use forEach here.
		void this.form.get('password')?.valueChanges.forEach((password) => this.setPassword(password));
		void this.form
			.get('passwordRetype')
			?.valueChanges.forEach((password) => this.setPasswordRetype(password));
		this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((data) => {
			parseCode(data.code!, this.form);
		});
		this.formErrors$ = formValidationErrors$(this.form, this.validationMessages);
	}
}
