import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RootReducer, Store } from '@app/app.reducers';
import { getGenericCategories, getMainCategories } from '@app/category/reducers/category.reducer';
import { getMainMenuContent, getPages } from '@app/core/reducers/content.reducer';
import { StoreDataService } from '@app/shared/services/store-data.service';
import { I18nService } from '@app/shared/utils/i18n.service';
import { getDebug } from '@app/shared/utils/util';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { pluckSuccessData } from '@granodigital/grano-remote-data';
import { UntilDestroy } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
import { filter, first, map, startWith } from 'rxjs/operators';

/** Used to check if a product route is active to highlight the navigation element. */
const productRoutes = new Set(['category', 'product', 'products']);

/** Main navigation component. */
@UntilDestroy()
@Component({
	selector: 'g-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
	readonly icons = { faCaretDown };
	private readonly debug = getDebug('NavigationComponent');

	/** Configuration for the product menu. */
	private readonly productMenuConfig = this.storeData.storeData.storefront?.product_menu;
	/** Whether to use the default product menu or mega menu. */
	readonly useDefaultProductMenu = (this.productMenuConfig?.type ?? 'default') === 'default';
	/** Get root categories. */
	readonly rootCategories$ = this.store.select(getMainCategories(this.i18n));
	/** Mega menu style (size). */
	readonly megaMenuStyle = this.productMenuConfig?.style ?? 'large';
	/** Mega menu category hierarchy. */
	readonly megaMenuCategories$ = combineLatest([
		this.store.select(getGenericCategories(this.i18n)),
	]).pipe(
		map(([categories]) => this.getMegaMenuCategories(categories)),
		this.debug.observe('megaMenuCategories$'),
	);
	/** Should we highlight the product menu item. */
	readonly isProductRoute$ = this.router.events.pipe(
		filter((event): event is NavigationEnd => event instanceof NavigationEnd),
		map((event) => event.url),
		startWith(this.router.routerState.snapshot.url),
		map((url) => productRoutes.has(url.split('/')[1])),
	);
	/** Main menu links & pages. */
	readonly mainMenuContent$ = combineLatest([
		this.store.select(getMainMenuContent).pipe(pluckSuccessData),
		this.store.select(getPages).pipe(pluckSuccessData),
	]).pipe(
		first(),
		map(([mainMenu, pages]) => {
			return {
				links: mainMenu?.links ?? [],
				pages:
					(mainMenu?.pages?.length &&
						pages
							?.filter((page) => mainMenu.pages.includes(page.key))
							?.sort((a, b) => a.content.title.localeCompare(b.content.title))) ||
					[],
			};
		}),
	);
	readonly menuClass = `main-navigation-menu ${this.productMenuConfig?.type ?? 'default'}`;
	/** Content is fetched in content access guard. */
	readonly content$ = this.store.select(getMainMenuContent);
	readonly pages$ = this.store.select(getPages);

	constructor(
		private readonly store: Store<RootReducer.State>,
		private readonly router: Router,
		private readonly i18n: I18nService,
		private readonly storeData: StoreDataService,
	) {}

	/** Returns whether the given category is a root category. */
	private isRootCategory(this: void, category: api.CategoryDto): boolean {
		return category.path?.split('.').length === 2;
	}

	/** Returns the mega menu category hierarchy. */
	private getMegaMenuCategories(categories: api.CategoryDto[]): api.CategoryDto[][] {
		if (!categories?.length) return [];
		const megaMenuCategories = [] as api.CategoryDto[][];
		const rootCategories = categories.filter(this.isRootCategory);
		const firstSubCategories = categories.filter((c) => c.path?.split('.').length === 3);
		// Concatenate root categories and their first level children
		// so that megaMenuCategories becomes an array of arrays: [[], ..., []]
		for (const category of rootCategories) {
			const categoryAndItsChildren = [category];
			for (const c of firstSubCategories.filter((c) => c.path?.startsWith(`${category.path}.`)))
				categoryAndItsChildren.push(c);
			// Slice category array by child category amount
			megaMenuCategories.push(
				categoryAndItsChildren.slice(0, (this.productMenuConfig?.child_category_amount ?? 4) + 1),
			);
		}
		// Return the amount of category arrays specified by root category amount
		return megaMenuCategories.slice(0, this.productMenuConfig?.root_category_amount ?? 4);
	}
}
