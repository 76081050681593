import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RootReducer, Store } from '@app/app.reducers';
import { DialogBase } from '@app/dialog/dialog-base';
import { getStorefront } from '@app/shared/reducers/storefront.reducer';
import { CognitoService } from '@app/shared/services/cognito.service';
import { NewRelic } from '@app/shared/services/newrelic.service';
import {
	FormErrors,
	equalTo,
	formValidationErrors$,
	validateTerms,
} from '@app/shared/utils/form-helper';
import { syncPasswordScoreValidation } from '@app/shared/utils/password-score-validation';
import { firstNotEmpty } from '@app/shared/utils/util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first } from 'rxjs/operators';

/** Change password dialog component */
@UntilDestroy()
@Component({
	selector: 'g-change-password-dialog',
	templateUrl: './change-password-dialog.component.html',
	styleUrls: ['./change-password-dialog.component.scss'],
})
export class ChangePasswordDialogComponent extends DialogBase implements OnInit {
	changePasswordForm!: UntypedFormGroup;
	formErrors$!: FormErrors;
	showTerms = false;
	showTermsCheckbox = false;
	private redirectTo?: string;
	private readonly validationMessages = {
		oldPassword: {
			required: $localize`:@@ChangePasswordPasswordRequired:Password is required`,
			minlength: $localize`:@@ChangePasswordPasswordMinLength:Password must be at least 10 characters long`,
			maxlength: $localize`:@@ChangePasswordPasswordMaxLength:Password cannot be over 255 characters long`,
		},
		password: {
			required: $localize`:@@ChangePasswordPasswordRequired:Password is required`,
			minlength: $localize`:@@ChangePasswordPasswordMinLength:Password must be at least 10 characters long`,
			maxlength: $localize`:@@ChangePasswordPasswordMaxLength:Password cannot be over 255 characters long`,
		},
		confirm_password: {
			required: $localize`:@@ChangePasswordConfirmPasswordRequired:Confirmation password is required`,
			equalTo: $localize`:@@ChangePasswordConfirmPasswordMatch:Passwords must match`,
		},
		passwordScore: {
			pattern: $localize`:@@ChangePasswordPasswordScoreTooLow:Password is too weak`,
		},
		acceptTerms: {
			required: $localize`:@@AcceptUserTermsRequired:You must accept terms and conditions`,
			acceptTerms: $localize`:@@AcceptUserTermsRequired:You must accept terms and conditions`,
		},
	};

	constructor(
		public cognito: CognitoService,
		protected store: Store<RootReducer.State>,
		private readonly router: Router,
		private readonly fb: UntypedFormBuilder,
		private readonly newRelic: NewRelic,
	) {
		super(store);
	}

	/** Initialize component */
	ngOnInit(): void {
		this.buildForm();
		this.dialogData$.pipe(first()).subscribe((data: any) => (this.redirectTo = data?.redirectTo));
		// Add terms checkbox to form if necessary
		this.store
			.select(getStorefront)
			.pipe(firstNotEmpty)
			.subscribe((store) => {
				this.showTermsCheckbox = store?.storefront?.show_user_profile_terms_checkbox ?? false;
				this.buildForm();
			});
		syncPasswordScoreValidation(this.changePasswordForm, untilDestroyed(this));
	}

	/** Submit form */
	async onSubmit(): Promise<void> {
		try {
			const { oldPassword, password } = this.changePasswordForm.value;
			await this.cognito.changePassword(oldPassword, password);
			this.closeDialog();
			if (this.redirectTo) void this.router.navigate([this.redirectTo]);
			else void this.router.navigate(['/']);
		} catch (err) {
			this.cognito.handleCognitoException(err);
		}
	}

	/** Toggle terms visibility */
	toggleTerms(): void {
		this.showTerms = !this.showTerms;
	}

	/** Build form */
	private buildForm() {
		const oldPassword = new UntypedFormControl('', [
			Validators.required,
			Validators.minLength(10),
			Validators.maxLength(255),
		]);
		const password = new UntypedFormControl('', [
			Validators.required,
			Validators.minLength(10),
			Validators.maxLength(255),
		]);
		const confirmPassword = new UntypedFormControl('', [equalTo(password)]);
		this.changePasswordForm = this.fb.group({
			oldPassword,
			password,
			confirm_password: confirmPassword,
			// Score must start with 3 or 4.
			passwordScore: ['', [Validators.pattern(/^[34]$/)]],
			acceptTerms: [false, this.showTermsCheckbox ? [Validators.required, validateTerms] : []],
		});

		this.formErrors$ = formValidationErrors$(this.changePasswordForm, this.validationMessages);
	}
}
