import { I18nService } from '../utils/i18n.service';
import { setName } from '../utils/name-helper';
import { firstTruthy, getDebug, once } from '../utils/util';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { getCategories as fetchCategories } from '@app/api/action/Category';
import { FETCH_MULTIPLE_CONTENT, fetchMultipleContent, fetchPages } from '@app/api/action/Content';
import { RootReducer, Store, hideContent, showContent } from '@app/app.reducers';
import {
	CONFIRM_URL,
	LOGIN_URL,
	LOGIN_URL_REGEXP,
	REGISTRATION_STANDALONE,
	RESET_PASSWORD_URL,
} from '@app/app.router.urls';
import { getIsContentReceived, getIsLoading } from '@app/core/reducers/content.reducer';
import { getStore, getStorefront } from '@app/shared/reducers/storefront.reducer';
import { PreviewTokenService } from '@app/shared/services/preview-token.service';
import {
	getCriticalCognitoError,
	setCriticalCognitoError,
	setIsMaintenanceMode,
} from '@app/user/reducers/auth.reducer';
import { getIsNotLoggedIn, getIsUserChecked, getUser } from '@app/user/reducers/user.reducer';
import { isResolved, isSuccess } from '@granodigital/grano-remote-data';
import { combineLatest, firstValueFrom } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';

export const PAGE_LOAD_CONTENT_KEYS = [
	'vignette',
	'mainmenu',
	'footer',
	'notification-banner',
	'custom-html',
	'homepage-seo',
] as const;

export interface RouteProperties {
	path?: string;
	params?: Params;
	queryParams?: Params;
}

/** Ensure that the user is logged in and has access to the requested content */
@Injectable({ providedIn: 'root' })
export class ContentAccessGuardService {
	private readonly debug = getDebug('ContentAccessGuardService');
	private previewToken?: string;
	/**
	 * Setup watchers that update when store/user status changes
	 */
	private readonly setup = once((route: ActivatedRouteSnapshot) => {
		this.setupPreviewToken(route);
		this.setupCognitoErrorHandler();
	});

	constructor(
		private readonly router: Router,
		private readonly store: Store<RootReducer.State>,
		private readonly previewTokenService: PreviewTokenService,
		private readonly i18n: I18nService,
	) {}

	/** Check if user has access to requested content */
	async canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Promise<boolean | UrlTree> {
		const debug = this.debug.extend('can-activate');
		debug('start');
		this.setup(route);
		if (await this.isMaintenanceMode()) {
			this.store.dispatch(setIsMaintenanceMode(true));
			debug(false, 'because maintenance mode');
			return false;
		}
		if (await this.shouldRedirectToLogin(state)) {
			const redirectTo = this.getRedirectTo(state, route);
			const queryParams = redirectTo ? { 'redirect-to': redirectTo } : {};
			this.store.dispatch(hideContent());
			debug('redirect to login', { redirectTo });
			return this.router.createUrlTree([LOGIN_URL], { queryParams });
		}
		// If all checks return false, proceed to load and display content.
		await this.loadAndShowContent(state);
		debug(true);
		return true;
	}

	/**
	 * Load page content when applicable and set content visible (such as menus, footer, pages etc)
	 */
	private async loadAndShowContent(state: RouterStateSnapshot) {
		const debug = this.debug.extend('load-and-show-content');
		debug('start');
		// Don't fetch/show content on login or reset password pages.
		// Don't reload content if it's already received or loading.
		if (
			this.isAlwaysAccessible(state) ||
			(await combineLatest([this.store.select(getIsContentReceived), this.store.select(getIsLoading)])
				.pipe(
					first(),
					map(([isReceived, isLoading]) => isReceived || isLoading),
				)
				.toPromise())
		) {
			debug('skip content fetching');
			return;
		}

		const store = (await firstValueFrom(this.store.select(getStorefront)))!;
		// Fetch categories and products.
		this.store.dispatch(setName('fetchCategories', fetchCategories()));

		if (PAGE_LOAD_CONTENT_KEYS.every((key) => !!store.content?.[key])) {
			debug('using preloaded content');
			this.store.dispatch({
				type: FETCH_MULTIPLE_CONTENT,
				error: false,
				payload: store.content,
			});
			this.store.dispatch(showContent());
		} else {
			debug('fetching content from api');
			const keys = PAGE_LOAD_CONTENT_KEYS.join(',');
			this.store.dispatch(
				setName('fetchMultipleContent', fetchMultipleContent(this.i18n.locale, { keys })),
			);
			// Wait until content is not pending to show content.
			this.store
				.select(getIsLoading)
				.pipe(first((isLoading) => !isLoading))
				.subscribe(() => this.store.dispatch(showContent()));
		}
		this.store.dispatch(setName('fetchPages', fetchPages(this.i18n.locale)));
	}

	// TODO: This seems to belong to auth guard.
	/**
	 * Parse preview token from query parameters
	 * @param route Route snapshot
	 */
	private setupPreviewToken(route: ActivatedRouteSnapshot) {
		if (route.queryParamMap?.keys?.length > 0)
			this.previewTokenService.setTokenFromQueryParamMap(route.queryParamMap);
		this.previewToken = this.previewTokenService.getTokenValue();
	}

	/**
	 * Checks whether we should display maintenance mode or not.
	 */
	private async isMaintenanceMode() {
		return this.store
			.select(getStore)
			.pipe(
				first((store) => isResolved(store)),
				switchMap(async (store) => {
					// Make sure the store published or the user is a super admin or has a preview token.
					if (isSuccess(store)) {
						if (store.data?.is_public) return false;
						await this.store.select(getIsUserChecked).pipe(firstTruthy).toPromise();
						const user = await firstValueFrom(this.store.select(getUser));
						const hasPreviewToken = !!this.previewToken;
						const isSuperAdmin = user?.isSuperAdmin;
						this.debug('checkMaintenanceMode', { isSuperAdmin, hasPreviewToken });
						return !isSuperAdmin && !hasPreviewToken;
					}
					return true;
				}),
				first(),
			)
			.toPromise();
	}

	// TODO: This seems to belong to auth guard.
	/**
	 * returns true when user must be forced to login page
	 */
	private async shouldRedirectToLogin(state: RouterStateSnapshot) {
		const debug = this.debug.extend('should-redirect-to-login');

		if (this.isAlwaysAccessible(state)) {
			debug(false, 'because isAlwaysAccessible:', true);
			return false;
		}

		return firstValueFrom(
			this.store.select(getStorefront).pipe(
				firstTruthy,
				switchMap((store) => {
					const isAnonBrowsingAllowed = store?.storefront?.allow_anonymous_browsing || false;
					if (isAnonBrowsingAllowed) {
						debug(false, 'because isAnonBrowsingAllowed:', isAnonBrowsingAllowed);
						return Promise.resolve(false);
					}
					debug('waiting for auth init');
					return this.store.select(getIsUserChecked).pipe(
						firstTruthy,
						switchMap(() => this.store.select(getIsNotLoggedIn)),
						first(),
						tap((isUserNotLoggedIn) =>
							debug(isUserNotLoggedIn, 'because isUserLoggedIn:', !isUserNotLoggedIn),
						),
					);
				}),
			),
		);
	}

	// TODO: This seems to belong to auth guard.
	/**
	 * Setup watcher which checks for Cognito errors and redirects user to root path when one occurs
	 */
	private setupCognitoErrorHandler() {
		this.store
			.select(getCriticalCognitoError)
			.pipe(filter((error) => error !== undefined))
			.subscribe(() => {
				void this.router.navigate(['/']);
				this.store.dispatch(setCriticalCognitoError(undefined));
			});
	}

	/**
	 * Check if user is on a page which is always accessible such as login or registration page
	 */
	private isAlwaysAccessible(state: RouterStateSnapshot) {
		const path = state?.url || '';
		const isOnLoginPage = path.startsWith(LOGIN_URL);
		const isOnUserConfirmPage = path.startsWith(CONFIRM_URL);
		const isOnResetPasswordPage = path.startsWith(RESET_PASSWORD_URL);
		const isOnRegistrationPage = path.startsWith(REGISTRATION_STANDALONE);
		return isOnLoginPage || isOnUserConfirmPage || isOnResetPasswordPage || isOnRegistrationPage;
	}

	/**
	 * Get router path from route properties.
	 * @returns URL friendly redirect path
	 */
	private getRedirectTo(state: RouterStateSnapshot, route: ActivatedRouteSnapshot): string | undefined {
		const path = state?.url;
		const queryParams = route.queryParams;
		const hasRedirectTo =
			typeof queryParams?.['redirect-to'] === 'string' && queryParams?.['redirect-to'].length > 0;
		let redirectTo: string | undefined;
		if (path && hasRedirectTo) redirectTo = queryParams['redirect-to'] as string;
		if (!redirectTo)
			redirectTo = path && !LOGIN_URL_REGEXP.test(path) ? encodeURIComponent(path) : undefined;
		return redirectTo;
	}
}
