import { NavActionsComponent } from '../header/nav-actions/nav-actions.component';
import { SearchFieldComponent } from '../search-field/search-field.component';
import { AsyncPipe, DOCUMENT, NgClass, UpperCasePipe } from '@angular/common';
import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatAutocompleteOrigin } from '@angular/material/autocomplete';
import { MatButton } from '@angular/material/button';
import { NavigationStart, Router, RouterLink } from '@angular/router';
import { RootReducer, Store } from '@app/app.reducers';
import {
	getMainMenuContent,
	getPages,
	getVignetteContent,
	PageResponse,
} from '@app/core/reducers/content.reducer';
import { closeMobileMenu, getMobileMenuStatus } from '@app/core/reducers/mobile-menu.reducer';
import { IconComponent } from '@app/shared/components/icon/icon.component';
import { ResponsiveImageDirective } from '@app/shared/directives/responsive-image.directive';
import { IsExternalLinkPipe, IsRouterLinkPipe } from '@app/shared/pipes/is-link.pipe';
import { CognitoService } from '@app/shared/services/cognito.service';
import { StoreDataService } from '@app/shared/services/store-data.service';
import { setCookie } from '@app/shared/utils/cookie-helper';
import { I18nService } from '@app/shared/utils/i18n.service';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { pluckSuccessData } from '@granodigital/grano-remote-data';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable } from 'rxjs';
import { delay, filter, map } from 'rxjs/operators';

/** Mobile navigation component. */
@UntilDestroy()
@Component({
	standalone: true,
	imports: [
		AsyncPipe,
		UpperCasePipe,
		NgClass,
		RouterLink,
		IconComponent,
		NavActionsComponent,
		IsRouterLinkPipe,
		IsExternalLinkPipe,
		SearchFieldComponent,
		ResponsiveImageDirective,
		MatAutocompleteOrigin,
		MatButton,
		FlexLayoutModule, // TODO: Remove.
	],
	selector: 'g-mobile-navigation',
	templateUrl: './mobile-navigation.component.html',
	styleUrls: ['./mobile-navigation.component.scss'],
})
export class MobileNavigationComponent implements OnInit {
	@HostBinding('class.is-overlaid') isOverlaid = false;
	@HostBinding('class.is-open') isOpen = false;
	readonly mobileLogo = this.storeData.mobileLogo;
	readonly pages$ = this.store.select(getPages).pipe(pluckSuccessData);
	readonly mainMenu$ = this.store.select(getMainMenuContent).pipe(pluckSuccessData);
	readonly mainMenuPages$ = this.pagesAlphabetically$(this.mainMenu$);
	readonly vignette$ = this.store.select(getVignetteContent).pipe(pluckSuccessData);
	readonly vignettePages$ = this.pagesAlphabetically$(this.vignette$);
	readonly languageCodes = this.i18n.enabledLocales;
	readonly currentLocale = this.i18n.locale;
	readonly icons = { faChevronLeft, faUser, faAngleRight, faXmark };

	constructor(
		private readonly store: Store<RootReducer.State>,
		private readonly router: Router,
		private readonly cognito: CognitoService,
		private readonly i18n: I18nService,
		private readonly storeData: StoreDataService,
		@Inject(DOCUMENT) private readonly document: Document,
	) {}

	readonly closeMenu = this.store.dispatch.bind(this.store, closeMobileMenu());

	/** Component initialization. */
	ngOnInit(): void {
		// TODO: Refactor to use animations.
		// Watch for menu being opened
		this.store
			.select(getMobileMenuStatus)
			.pipe(
				filter((status) => status !== undefined),
				untilDestroyed(this),
			)
			.subscribe((isOpen) => {
				if (isOpen && !this.isOpen) {
					this.isOverlaid = true;
					this.isOpen = true;
				} else if (!isOpen && this.isOpen) {
					// Don't remove overlay yet so CSS transitions have time to finish
					this.isOpen = false;
				}
			});
		// Watch for menu being closed
		this.store
			.select(getMobileMenuStatus)
			.pipe(
				filter((status) => status !== undefined),
				delay(300),
				untilDestroyed(this),
			)
			.subscribe((isOpen) => {
				if (!isOpen && this.isOverlaid) {
					// Hide overlay 300ms after the menu has been closed
					this.isOverlaid = false;
				}
			});

		// Close menu on navigation event.
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationStart),
				untilDestroyed(this),
			)
			.subscribe(() => this.closeMenu());
	}

	/** Handle logout. */
	async logout(): Promise<void> {
		this.closeMenu();
		await this.cognito.logout();
	}

	/** Change language. */
	changeLanguage(languageCode: LocaleCode): void {
		setCookie('locale', languageCode, 365);
		this.document.location.href = `/${languageCode}${this.router.url}`;
	}

	/** Get pages from the menu & page content and sort them alphabetically. */
	private pagesAlphabetically$(menu$: Observable<{ pages: string[] }>): Observable<PageResponse[]> {
		return combineLatest([menu$, this.pages$]).pipe(
			map(([menu, pages]) => {
				const selectedPages = pages?.filter((page) => menu?.pages?.includes(page.key)) ?? [];
				return selectedPages.sort((a, b) => a.content.title.localeCompare(b.content.title));
			}),
		);
	}
}
