import { GET_MY_ORDERS } from '@app/api/action/Order';
import { RootReducer } from '@app/app.reducers';
import { setName } from '@app/shared/utils/name-helper';
import { ApiAction } from '@granodigital/grano-remote-data';
import { createSelector } from 'reselect';

export interface State {
	myOrders?: api.OrderDto[];
}

export const initialState: State = {
	myOrders: undefined,
};

type OrderAction = ApiAction<typeof GET_MY_ORDERS, api.OrderDto[]>;

export function reducer(state = initialState, action: OrderAction): State {
	switch (action.type) {
		case GET_MY_ORDERS:
			return { ...state, myOrders: action.error !== true ? action.payload : [] };

		default:
			return state;
	}
}

export const getState = (state: RootReducer.State) => state.order;
export const getMyOrders = setName(
	'getMyOrders',
	createSelector(getState, (state) => state.myOrders),
);
export const getOrderByOrderNumber = (orderNumber: string) =>
	setName(
		'getOrderByOrderNumber',
		createSelector(getMyOrders, (orders) => orders?.find((order) => order.order_number === orderNumber)),
	);
