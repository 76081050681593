import { FaultPageComponent } from './components/fault-page/fault-page.component';
import { CustomHtmlBlockComponent } from './components/main/custom-html-block/custom-html-block.component';
import { FooterComponent } from './components/main/footer/footer.component';
import { HeaderComponent } from './components/main/header/header.component';
import { NavActionsComponent } from './components/main/header/nav-actions/nav-actions.component';
import { NavigationComponent } from './components/main/header/navigation/navigation.component';
import { PreviewBannerComponent } from './components/main/header/preview-banner/preview-banner.component';
import { VignetteComponent } from './components/main/header/vignette/vignette.component';
import { MainComponent } from './components/main/main.component';
import { MobileNavigationComponent } from './components/main/mobile-navigation/mobile-navigation.component';
import { OrderConfirmedComponent } from './components/main/order-confirmed/order-confirmed.component';
import { PaymentCancelledComponent } from './components/main/payment-cancelled/payment-cancelled.component';
import { SearchFieldComponent } from './components/main/search-field/search-field.component';
import { ResetPasswordComponent } from './components/registration/reset-password/reset-password.component';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { ContentPageComponent } from '@app/shared/components/content-page/content-page.component';
import { IconComponent } from '@app/shared/components/icon/icon.component';
import { OrderSummaryComponent } from '@app/shared/components/order-summary/order-summary.component';
import { SelectComponent } from '@app/shared/components/select/select.component';
import { IsExternalLinkPipe, IsRouterLinkPipe } from '@app/shared/pipes/is-link.pipe';
import { TrustedHtmlPipe } from '@app/shared/pipes/trusted-html.pipe';
import { ContentAccessGuardService } from '@app/shared/services/content-access-guard.service';
import { PunchOutGuardService } from '@app/shared/services/punch-out-guard.service';
import { ResolveStoreService } from '@app/shared/services/resolve-store.service';
import { SharedModule } from '@app/shared/shared.module';
import { IsSuccessPipe } from '@app/shared/utils/template-helpers';

const coreRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild([
	{
		path: '',
		component: MainComponent,
		canActivate: [ContentAccessGuardService],
		data: {
			showNotificationBanner: true,
			showCustomHtml: true,
			showFooter: true,
		},
	},
	// TODO: Move Away from core for lazy loading.
	{
		path: 'reset-password',
		component: ResetPasswordComponent,
		canActivate: [ContentAccessGuardService],
		data: {
			showVignetteHeader: false,
			showHeader: false,
			showFooter: false,
			showNewsletter: false,
			isStandalone: true,
		},
	},
	// TODO: Move Away from core for lazy loading.
	{
		path: 'page/:contentKey',
		component: ContentPageComponent,
		canActivate: [ContentAccessGuardService],
		resolve: { content: ResolveStoreService },
	},
	// TODO: Move Away from core for lazy loading.
	{
		path: 'faq',
		component: ContentPageComponent,
		canActivate: [ContentAccessGuardService],
		resolve: { content: ResolveStoreService },
	},
	// TODO: Move Away from core for lazy loading.
	{
		path: 'terms',
		component: ContentPageComponent,
		canActivate: [ContentAccessGuardService],
		resolve: { content: ResolveStoreService },
	},
	// TODO: Move Away from core for lazy loading.
	{
		path: 'about',
		component: ContentPageComponent,
		canActivate: [ContentAccessGuardService],
		resolve: { content: ResolveStoreService },
	},
	// TODO: Move Away from core for lazy loading.
	{
		path: 'support',
		loadChildren: () => import('./components/main/support/support.module').then((m) => m.SupportModule),
		canActivate: [ContentAccessGuardService],
	},
	// TODO: Move Away from core for lazy loading.
	{
		path: 'order-confirmed/:token',
		component: OrderConfirmedComponent,
		canActivate: [ContentAccessGuardService],
	},
	{
		path: 'order-confirmed',
		redirectTo: 'order-confirmed/',
		pathMatch: 'full',
	},
	// TODO: Remove Paytrail V1
	{
		path: 'payment-cancelled/:token',
		component: PaymentCancelledComponent,
		canActivate: [ContentAccessGuardService],
	},
	// TODO: Move Away from core for lazy loading.
	{
		path: 'payment-cancelled', // Paytrail V2
		component: PaymentCancelledComponent,
		canActivate: [ContentAccessGuardService],
	},
	// TODO: Move Away from core for lazy loading.
	{
		path: 'material-instructions',
		component: ContentPageComponent,
		canActivate: [ContentAccessGuardService],
		resolve: { content: ResolveStoreService },
	},
	{
		path: 'punchout',
		canActivate: [PunchOutGuardService],
		// MainComponent is never loaded because the guard will always redirect, but
		// redirectTo does not call the Guard so we must define a component.
		component: MainComponent,
	},
]);

/** Core module. */
@NgModule({
	imports: [
		coreRouting,
		SharedModule,
		OrderSummaryComponent,
		IconComponent,
		SelectComponent,
		MatMenuModule,
		MatAutocompleteModule,
		IsSuccessPipe,
		SearchFieldComponent,
		IsRouterLinkPipe,
		IsExternalLinkPipe,
		NavActionsComponent,
		MobileNavigationComponent,
		TrustedHtmlPipe,
	],
	declarations: [
		MainComponent,
		HeaderComponent,
		NavigationComponent,
		ResetPasswordComponent,
		VignetteComponent,
		FooterComponent,
		OrderConfirmedComponent,
		PaymentCancelledComponent,
		FaultPageComponent,
		PreviewBannerComponent,
		CustomHtmlBlockComponent,
	],
	exports: [
		HeaderComponent,
		VignetteComponent,
		FooterComponent,
		FaultPageComponent,
		PreviewBannerComponent,
		CustomHtmlBlockComponent,
		MobileNavigationComponent,
	],
})
export class CoreModule {}
