// TODO: Create a shared library for common Angular utilities and move this file there (ED-2252).
// Helpers for the templates //
//////////////////////////////
import { castArray } from './util';
import { Pipe, PipeTransform } from '@angular/core';
import { foldSuccess, isResolved, isSuccess, RemoteData } from '@granodigital/grano-remote-data';

/** Return the value of a RemoteData object if it is in a `Success` state otherwise undefined. */
@Pipe({ name: 'foldSuccess', standalone: true })
export class FoldSuccessPipe implements PipeTransform {
	/** Return the value of a RemoteData object if it is in a `Success` state otherwise undefined. */
	transform<T>(data: RemoteData<unknown, T>): T | undefined {
		return foldSuccess(data);
	}
}

/** Check if all given RemoteData object(s) are in a `resolved` state. */
@Pipe({ name: 'isResolved', standalone: true })
export class IsResolvedPipe implements PipeTransform {
	/** Check if all given RemoteData object(s) are in a `resolved` state. */
	transform(source: OneOrArray<RemoteData<unknown, unknown>>): boolean {
		return castArray(source).every(isResolved);
	}
}

/** Check if all given RemoteData object(s) are in a `Success` state. */
@Pipe({ name: 'isSuccess', standalone: true })
export class IsSuccessPipe implements PipeTransform {
	/** Check if all given RemoteData object(s) are in a `Success` state. */
	transform(source: OneOrArray<RemoteData<unknown, unknown>>): boolean {
		return castArray(source).every(isSuccess);
	}
}
