import { filterTruthy } from '../utils/util';
import { Injectable } from '@angular/core';
import { generateProofImages } from '@app/api/CustomersCanvas';
import { RootReducer, Store } from '@app/app.reducers';
import {
	getCartItem,
	modifyProduct,
} from '@app/checkout/modules/checkout-shared/reducers/checkout.reducer';
import {
	getProductListProduct,
	updateListProduct,
} from '@app/checkout/modules/checkout-shared/reducers/persisted-list.reducer';
import { isApiError } from '@granodigital/grano-remote-data';
import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

export interface ProductProofImages {
	proofImageThumbnail: string;
	scaledProofImages: string[];
}

export interface ProofImageOptions {
	maxWidth: number;
	maxHeight: number;
}
export const PROOF_IMAGE_OPTIONS: ProofImageOptions = { maxWidth: 1024, maxHeight: 1024 };
export const PROOF_IMAGE_THUMBNAIL_OPTIONS: ProofImageOptions = { maxWidth: 80, maxHeight: 80 };

/** Service for generating proof images for a product. */
@Injectable({ providedIn: 'root' })
export class ProofImageService {
	constructor(private readonly store: Store<RootReducer.State>) {}

	/**
	 * Calls the proof image generation api
	 * @param stateId Editor file state id
	 * @param userId Editor file folder/user id
	 * @param previewOptions Image formatting options passed on to the Customer's Canvas api
	 * @returns Array of image urls
	 */
	async getProofImages(
		stateId: string,
		userId: string,
		previewOptions: api.GenerateProofImagesParameters['previewOptions'],
	): Promise<string[]> {
		const response = await generateProofImages({ stateId, userId, previewOptions });
		// TODO: Throw proper errors.
		// eslint-disable-next-line @typescript-eslint/only-throw-error
		if (isApiError(response)) throw response.data;
		const imageUrls = response.data?.proofImageUrls as string[][];
		return imageUrls.flat().filter(Boolean);
	}

	/**
	 * Generates editor file thumbnail and larger proof images
	 * @param stateId Editor file state id
	 * @param userId Editor file folder/user id
	 * @returns Proof images of a product
	 */
	async getProductProofImages(stateId: string, userId: string): Promise<ProductProofImages> {
		const proofImageThumbnails = await this.getProofImages(
			stateId,
			userId,
			PROOF_IMAGE_THUMBNAIL_OPTIONS,
		);
		const scaledProofImages = await this.getProofImages(stateId, userId, PROOF_IMAGE_OPTIONS);
		return {
			proofImageThumbnail: proofImageThumbnails[0],
			scaledProofImages,
		};
	}

	/**
	 * Regenerates proof images for a My Products item and updates the item in the product list
	 * @param productId Id of the product
	 * @param stateId Editor file state id
	 * @param userId Editor file folder/user id
	 * @returns The generated proof images for the item
	 */
	regenerateMyProductProofImages(
		productId: string,
		stateId: string,
		userId: string,
	): Observable<ProductProofImages> {
		return this.store.select(getProductListProduct('my-products', productId)).pipe(
			first(),
			filterTruthy,
			switchMap(async (product) => {
				const images = await this.getProductProofImages(stateId, userId);
				this.store.dispatch(
					updateListProduct('my-products', {
						...product,
						productFlowData: { ...product.productFlowData!, ...images },
					}),
				);
				return images;
			}),
		);
	}

	/**
	 * Regenerates proof images for a shopping cart item and updates the item in the cart
	 * @param cartItemId Id of the shopping cart item
	 * @param stateId Editor file state id
	 * @param userId Editor file folder/user id
	 * @returns The generated proof images for the item
	 */
	regenerateCartItemProofImages(
		cartItemId: string,
		stateId: string,
		userId: string,
	): Observable<ProductProofImages> {
		return this.store.select(getCartItem(cartItemId)).pipe(
			first(),
			filterTruthy,
			switchMap(async (item) => {
				const images = await this.getProductProofImages(stateId, userId);
				this.store.dispatch(
					modifyProduct(cartItemId, item.product, item.options, item.price, {
						...item.productFlowData!,
						...images,
					}),
				);
				return images;
			}),
		);
	}
}
