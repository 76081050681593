import type { CartItem } from './checkout.reducer';
import * as ApiProductList from '@app/api/action/ProductList';
import { RootReducer } from '@app/app.reducers';
import { isApiActionSuccess } from '@app/shared/utils/api-utils';
import { setName } from '@app/shared/utils/name-helper';
import { isPlainObjectEqual } from '@app/shared/utils/util';
import {
	ApiAction,
	ApiError,
	ApiState,
	Failure,
	Initialized,
	Pending,
	Success,
	fold,
	foldSuccess,
	isInitialized,
	isPending,
} from '@granodigital/grano-remote-data';
import { createSelector } from 'reselect';

export type ProductListType = api.ProductListWithProductsDto['type'];

// TODO: Export DTO from API.
export type ProductListDto = Override<api.ProductListWithProducts, { products: api.CartItemDto[] }>;

export type ProductListWithProducts = Override<
	api.ProductListWithProducts,
	{ products: api.CartItemWithProductDto[] }
>;

export interface State {
	initialized: boolean;
	cart: ApiState<ProductListWithProducts>;
	myProducts: ApiState<ProductListWithProducts>;
}

export const initialState: State = {
	initialized: false,
	cart: new Initialized(),
	myProducts: new Initialized(),
};

//#region Helpers

export const cartItemsEqual = <T extends api.CartItemWithProductDto | api.CartItemDto>(
	i1: T,
	i2: T,
): boolean =>
	i1.id === i2.id &&
	// TODO: Fix types.
	(i1?.options as any)?.quantity === (i2?.options as any)?.quantity &&
	i1?.options?.workTitle === i2?.options?.workTitle &&
	isPlainObjectEqual(i1?.options?.printfile, i2?.options?.printfile, ['report']) &&
	isPlainObjectEqual(i1?.options?.printfile?.report, i2?.options?.printfile?.report, [
		'errors',
		'warnings',
	]) &&
	isPlainObjectEqual(
		i1?.options?.productEditorChanges?.userChanges,
		i2?.options?.productEditorChanges?.userChanges,
	) &&
	isPlainObjectEqual(i1?.options?.selectedOptions, i2?.options?.selectedOptions);

const stateKeyByListType = new Map<ProductListType, 'cart' | 'myProducts'>([
	['persisted-shopping-cart', 'cart'],
	['my-products', 'myProducts'],
]);

const createMyProductsList =
	(products: ProductListWithProducts['products'] = []) =>
	() =>
		new Success({
			...myProductsListTemplate,
			metadata: { ...myProductsListTemplate.metadata },
			products,
		});

export const shoppingCartListTemplate: ProductListWithProducts = {
	type: 'persisted-shopping-cart',
	products: [],
	metadata: { title: 'Shopping Cart' },
};

export const myProductsListTemplate: ProductListWithProducts = {
	type: 'my-products',
	metadata: { title: 'My Products' },
	products: [],
};

/** Return whether a list is a shopping cart */
export function isShoppingCart(list: ProductListDto | ProductListWithProducts): boolean {
	return list?.type === 'persisted-shopping-cart';
}
/** Return whether a list is a my products list */
export function isMyProducts(list: ProductListDto | ProductListWithProducts): boolean {
	return list?.type === 'my-products';
}

export const sortByTypeAndUpdatedAt = (
	a: ProductListDto | ProductListWithProducts,
	b: ProductListDto | ProductListWithProducts,
) => {
	if (a.updated_at && b.updated_at && a.type === b.type) {
		return new Date(a.updated_at).getTime() > new Date(b.updated_at).getTime() ? -1 : 1;
	}
	return a.type.localeCompare(b.type);
};

const listFail = (type: ProductListType) => () =>
	new Failure(new Error(`List "${type}" is not available`));

//#endregion

//#region Actions

export const RESET_PRODUCT_LIST_STATE = 'mygrano/productlist/RESET_PRODUCT_LIST_STATE';
export const resetProductLists = () => ({ type: RESET_PRODUCT_LIST_STATE }) as const;

export const UPDATE_MY_PRODUCTS = 'mygrano/productlist/UPDATE_MY_PRODUCTS';
export const updateMyProducts = (products: api.CartItemWithProductDto[]) =>
	({
		type: UPDATE_MY_PRODUCTS,
		payload: products,
	}) as const;

export const ADD_TO_PRODUCT_LIST = 'mygrano/productlist/ADD_TO_PRODUCT_LIST';
export const addToProductList = (type: ProductListDto['type'], product: CartItem) =>
	({
		type: ADD_TO_PRODUCT_LIST,
		payload: { type, product },
	}) as const;

export const REMOVE_FROM_PRODUCT_LIST = 'mygrano/productlist/REMOVE_FROM_PRODUCT_LIST';
export const removeFromProductList = (type: ProductListDto['type'], product: CartItem) =>
	({
		type: REMOVE_FROM_PRODUCT_LIST,
		payload: { type, product },
	}) as const;

export const UPDATE_LIST_PRODUCT = 'mygrano/productlist/UPDATE_LIST_PRODUCT';
export const updateListProduct = (type: ProductListDto['type'], product: CartItem) =>
	({
		type: UPDATE_LIST_PRODUCT,
		payload: { type, product },
	}) as const;

export const SET_INITIALIZED = 'mygrano/productlist/SET_INITIALIZED';
export const setInitialized = (initialized: boolean) =>
	({
		type: SET_INITIALIZED,
		payload: initialized,
	}) as const;

// #endregion

//#region Reducer

type PersistedListAction =
	| ReturnType<
			| typeof resetProductLists
			| typeof updateMyProducts
			| typeof addToProductList
			| typeof removeFromProductList
			| typeof updateListProduct
			| typeof setInitialized
	  >
	| ApiAction<typeof ApiProductList.MY_LISTS_START, never>
	| ApiAction<typeof ApiProductList.MY_LISTS, ProductListWithProducts[]>
	| ApiAction<
			typeof ApiProductList.CREATE_START,
			never,
			{ type: ProductListType },
			{ options: { body: { type: ProductListType } } }
	  >
	| ApiAction<
			typeof ApiProductList.UPDATE_START,
			never,
			{ type: ProductListType },
			{ options: { body: { type: ProductListType } } }
	  >
	| ApiAction<typeof ApiProductList.CREATE, ProductListDto>
	| ApiAction<typeof ApiProductList.UPDATE, ProductListDto>;

/** Persisted lists reducer */
export function reducer(state: State = initialState, action: PersistedListAction): State {
	switch (action.type) {
		case RESET_PRODUCT_LIST_STATE:
			return { ...initialState, initialized: state.initialized };

		case ApiProductList.MY_LISTS_START:
			return { ...state, cart: new Pending(), myProducts: new Pending() };

		case ApiProductList.CREATE_START:
		case ApiProductList.UPDATE_START: {
			const listType = action.meta.params.options.body.type;
			return { ...state, [stateKeyByListType.get(listType)!]: new Pending() };
		}

		case ApiProductList.MY_LISTS:
			if (!isApiActionSuccess(action)) {
				return {
					...state,
					cart: new Failure(action.payload),
					myProducts: new Failure(action.payload),
				};
			} else {
				const sortedLists = [...action.payload].sort(sortByTypeAndUpdatedAt);
				return {
					...state,
					cart: new Success(sortedLists?.find(isShoppingCart) || shoppingCartListTemplate),
					myProducts: new Success(sortedLists?.find(isMyProducts) || myProductsListTemplate),
				};
			}

		case ApiProductList.CREATE:
		case ApiProductList.UPDATE: {
			if (!isApiActionSuccess(action))
				return {
					...state,
					cart: new Failure(action.payload),
					myProducts: new Failure(action.payload),
				};
			const listKey = stateKeyByListType.get(action.payload.type)!;
			return {
				...state,
				[listKey]: new Success(action.payload),
			};
		}

		case UPDATE_MY_PRODUCTS:
			return {
				...state,
				myProducts: fold<ApiState<ProductListWithProducts>, ApiError, ProductListWithProducts>(
					createMyProductsList(action.payload),
					createMyProductsList(action.payload),
					createMyProductsList(action.payload),
					(list) =>
						new Success({
							...list,
							metadata: { ...list.metadata },
							products: action.payload,
						}),
				)(state.myProducts),
			};

		case UPDATE_LIST_PRODUCT: {
			const type = action.payload.type;
			const modifiedItem = action.payload.product;
			const fail = () => new Failure(new Error(`List "${type}" could not be found`));
			return {
				...state,
				[stateKeyByListType.get(type)!]: fold<
					ApiState<ProductListWithProducts>,
					ApiError,
					ProductListWithProducts
				>(
					fail,
					fail,
					fail,
					(list) =>
						new Success({
							...list,
							products: list.products.map((item) =>
								item.id === modifiedItem.id ? (modifiedItem as api.CartItemWithProductDto) : item,
							),
						}),
				)(state[stateKeyByListType.get(type)!]),
			};
		}

		case ADD_TO_PRODUCT_LIST: {
			const type = action.payload.type;
			const newItem = action.payload.product;
			return {
				...state,
				[stateKeyByListType.get(type)!]: fold<
					ApiState<ProductListWithProducts>,
					ApiError,
					ProductListWithProducts
				>(listFail(type), listFail(type), listFail(type), (list) => {
					return new Success({
						...list,
						products: [...list.products, newItem as api.CartItemWithProductDto],
					});
				})(state[stateKeyByListType.get(type)!]),
			};
		}

		case REMOVE_FROM_PRODUCT_LIST: {
			const type = action.payload.type;
			const removedItem = action.payload.product;
			return {
				...state,
				[stateKeyByListType.get(type)!]: fold<
					ApiState<ProductListWithProducts>,
					ApiError,
					ProductListWithProducts
				>(
					listFail(type),
					listFail(type),
					listFail(type),
					(list) =>
						new Success({
							...list,
							products: list.products.filter((item) => item.id !== removedItem.id),
						}),
				)(state[stateKeyByListType.get(type)!]),
			};
		}

		case SET_INITIALIZED:
			return { ...state, initialized: action.payload };

		default:
			return state;
	}
}

// #endregion

//#region Selectors

export const getState = setName('getState', (state: RootReducer.State) => state.persistedLists);

// TODO: Remove fold success from here and handle different states where needed.

export const hasFetchedLists = setName(
	'hasFetchedLists',
	createSelector(
		getState,
		(state) =>
			(!isInitialized(state.cart) && !isPending(state.cart)) ||
			(!isInitialized(state.myProducts) && !isPending(state.myProducts)),
	),
);

export const getPersistedShoppingCart = setName(
	'getPersistedShoppingCart',
	createSelector(getState, (state) => foldSuccess(state.cart)),
);

export const getMyProducts = setName(
	'getMyProducts',
	createSelector(getState, (state) => state.myProducts),
);

export const hasInitialized = setName(
	'hasInitialized',
	createSelector(getState, (state) => state.initialized),
);

export const getProductListProduct = (type: ProductListType, itemId: string) =>
	setName(
		'getProductListProduct',
		createSelector(getState, (state) =>
			foldSuccess(state[stateKeyByListType.get(type)!])?.products?.find?.((item) => item.id === itemId),
		),
	);

// #endregion
