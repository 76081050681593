import { StoreDataService } from '../services/store-data.service';
import { Inject, Injectable, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

export type LocalizedValue<T> = Partial<Record<LocaleCode, T>>;
type OrderMetaKey = 'invoice_message' | 'order_message' | 'purchase_order_number';

/** i18n utility service */
@Injectable({ providedIn: 'root' })
export class I18nService {
	// Note that the order here is also the global fallback order.
	private readonly languageCodeMap = new Map<string, LocaleCode>([
		['english', 'en'],
		['finnish', 'fi'],
		['swedish', 'sv'],
	]);

	/** Get the enabled locales. */
	readonly enabledLocales: LocaleCode[] = [];

	constructor(
		@Inject(LOCALE_ID) public readonly locale: LocaleCode,
		private readonly storeData: StoreDataService,
	) {
		// Init is also be called by the app initializer once store data is available.
		this.init();
	}

	/** Initialize the service. */
	init(): void {
		// @ts-expect-error -- Written during init.
		this.enabledLocales = Object.entries(this.storeData.storeData?.storefront?.language ?? {})
			.filter(([_, isEnabled]) => isEnabled === true)
			.map(([language]) => this.languageCodeMap.get(language));
	}

	/** Extract a localized value. */
	getLocaleValue<T = unknown>(
		value?: LocalizedValue<T>,
		defaultValue: T | undefined = undefined,
	): T | undefined {
		if (!value || typeof value !== 'object') return;
		// eslint-disable-next-line no-restricted-syntax -- this is the helper.
		if (this.locale in value) return value[this.locale];

		for (const availableLocale of this.enabledLocales) {
			if (availableLocale in value) return value[availableLocale];
		}
		return defaultValue;
	}

	/** Extract a localized string. */
	getText(value?: api.LocaleStringDto, defaultValue = ''): string {
		return this.getLocaleValue(value) || defaultValue;
	}

	/** Extract a localized store value. */
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	getStoreLocaleValue<T extends Exclude<keyof api.StoreLocaleDto, OrderMetaKey | 'custom_features'>>(
		key: T,
	) {
		return this.getLocaleValue(this.storeData.storeData?.locale?.[key]);
	}

	/** Extract a localized store order meta value. */
	getOrderMetaLocale<T extends OrderMetaKey, T2 extends keyof api.LocalizedMessageConfigurationDto>(
		key: T,
		subKey: T2,
	): string {
		return this.getText(this.storeData.storeData?.locale?.[key]?.[subKey]);
	}

	/** Extract a localized store custom feature value. */
	getCustomFeatureLocale<T extends keyof api.CustomFeaturesDto>(
		key: T,
		defaultValue?: api.CustomFeaturesDto[T],
	): api.CustomFeaturesDto[T] | undefined {
		return this.getLocaleValue(this.storeData.storeData?.locale?.custom_features)?.[key] ?? defaultValue;
	}
}

/** Pipe to get text from a translation. */
@Pipe({ name: 'getText', pure: true, standalone: true })
export class GetTextPipe implements PipeTransform {
	constructor(private readonly i18n: I18nService) {}
	/** Pipe to get text from a translation. */
	transform(value?: api.LocaleStringDto): string {
		return this.i18n.getText(value);
	}
}
