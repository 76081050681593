import * as ApiContactRequest from '@app/api/action/ContactRequest';
import { RootReducer } from '@app/app.reducers';
import { setName } from '@app/shared/utils/name-helper';
import { createSelector } from 'reselect';

export interface ContactRequest {
	isProcessing: boolean;
	isSuccess: boolean;
	isError: boolean;
	content: {
		firstName: string;
		lastName: string;
		email: string;
		phone: string;
		preferredReplyMethod: string;
		message: string;
	};
}

export interface State {
	contactRequest: ContactRequest;
}

export const initialState: State = {
	contactRequest: {
		isProcessing: false,
		isSuccess: false,
		isError: false,
		content: {
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			preferredReplyMethod: '',
			message: '',
		},
	},
};

export function reducer(state: State = initialState, action: any) {
	switch (action.type) {
		case ApiContactRequest.SEND_START:
			return {
				...state,
				contactRequest: {
					...state.contactRequest,
					isProcessing: true,
					isSuccess: false,
					isError: false,
				},
			};

		case ApiContactRequest.SEND:
			return {
				...state,
				contactRequest: {
					...state.contactRequest,
					isProcessing: false,
					isSuccess: !action.error,
					isError: action.error,
					content: { ...initialState.contactRequest.content },
				},
			};

		default:
			return state;
	}
}

export const getState = setName('getState', (state: RootReducer.State) => state.contactRequest);

export const getContactRequest = setName(
	'getContactRequest',
	createSelector(getState, (state) => state.contactRequest),
);

export const getContactRequestProcessing = setName(
	'getContactRequestProcessing',
	createSelector(getContactRequest, (state) => state?.isProcessing),
);

export const getContactRequestSuccess = setName(
	'getContactRequestSuccess',
	createSelector(getContactRequest, (state) => state?.isSuccess),
);

export const getContactRequestError = setName(
	'getContactRequestError',
	createSelector(getContactRequest, (state) => state?.isError),
);
