/** @file Helpers for working with our Swagger API client. */
import { ServiceError } from '@app/api/gateway';
import { ApiAction, ApiActionSuccess, ApiError } from '@granodigital/grano-remote-data';

// TODO: move these to remote-data module?

/** Check if API response is error */
export function isApiSuccess<T>(
	response: api.Response<T>,
	// Exclude is needed here, T alone will include both types.
): response is api.Response<Exclude<T, ApiError>> {
	return !response.error;
}

/** Check that an API action is successful */
export function isApiActionSuccess<
	Type = string,
	TPayload = unknown,
	TMetaInfo = Record<string, unknown>,
	TMetaParams = Record<string, unknown>,
>(
	action: ApiAction<Type, TPayload, TMetaInfo, TMetaParams>,
): action is ApiActionSuccess<Type, TPayload, TMetaInfo, TMetaParams> {
	return !action.error;
}

/** Parses API action and returns either undefined or ApiError */
export function parseApiActionError<
	Type = string,
	TPayload = unknown,
	TMetaInfo = Record<string, unknown>,
	TMetaParams = Record<string, unknown>,
>(action: ApiAction<Type, TPayload, TMetaInfo, TMetaParams>): ApiError | undefined {
	if (!action.error) return undefined;

	const source = action.payload instanceof ServiceError ? action.payload.body : action.payload;

	return {
		message: source?.message || 'Unknown error',
		trace: source?.trace,
	};
}
