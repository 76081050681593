import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ContentPageComponent } from './components/content-page/content-page.component';
import { IconComponent } from './components/icon/icon.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { NumericInputComponent } from './components/numeric-input/numeric-input.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PasswordStrengthComponent } from './components/password-strength/password-strength.component';
import { ProductListThumbnailComponent } from './components/product-list/product-list-thumbnail/product-list-thumbnail.component';
import { ProductListComponent } from './components/product-list/product-list.component';
import { ProductOptionSelectorComponent } from './components/product-option-selector/product-option-selector.component';
import { ResponsiveImageComponent } from './components/responsive-image/responsive-image.component';
import { SelectComponent } from './components/select/select.component';
import { TranslatedStringsComponent } from './components/translated-strings/translated-strings.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { FallbackProductImageDirective } from './directives/fallback-product-image.directive';
import { FormAsteriskDirective } from './directives/form-asterisk.directive';
import { InputTrimDirective } from './directives/input-trim.directive';
import { LinkRewriteDirective } from './directives/link-rewrite.directive';
import {
	PrintFlexDirective,
	PrintLayoutAlignDirective,
	PrintLayoutDirective,
} from './directives/print.directive';
import { ResponsiveImageDirective } from './directives/responsive-image.directive';
import { IsHiddenCategoryPipe } from './pipes/is-hidden-category.pipe';
import { ParseIntPipe } from './pipes/parse-int.pipe';
import { TrimPipe } from './pipes/trim.pipe';
import { ValidationFieldPipe } from './pipes/validation-field.pipe';
import { WithLineBreaksPipe } from './pipes/with-line-breaks.pipe';
import { GetTextPipe } from './utils/i18n.service';
import { FoldSuccessPipe, IsResolvedPipe, IsSuccessPipe } from './utils/template-helpers';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { PrintBreakpointsProvider } from '@app/shared/providers/print-breakpoints.provider';
import { GdsAngularModule } from '@grano/gds-angular';

// TODO: Convert into standalone components and move to their respective modules.

/** Contains shared components, directives, pipes, and providers. */
@NgModule({
	imports: [
		CommonModule,
		FlexLayoutModule,
		FormsModule,
		GdsAngularModule,
		MatButtonModule,
		MatTooltip,
		MatSnackBarModule,
		ReactiveFormsModule,
		RouterModule,
		TrimPipe,
		// TODO: Remove.
		SelectComponent,
		IconComponent,
		GetTextPipe,
		IsSuccessPipe,
		IsResolvedPipe,
		FoldSuccessPipe,
		ResponsiveImageDirective,
		ValidationFieldPipe,
		InputTrimDirective,
	],
	declarations: [
		AutofocusDirective,
		BreadcrumbComponent,
		ContentPageComponent,
		FallbackProductImageDirective,
		FormAsteriskDirective,
		IsHiddenCategoryPipe,
		LinkRewriteDirective,
		LoadingSpinnerComponent,
		LoginFormComponent,
		NumericInputComponent,
		PaginatorComponent,
		PasswordStrengthComponent,
		PrintFlexDirective,
		PrintLayoutAlignDirective,
		PrintLayoutDirective,
		ProductListComponent,
		ProductListThumbnailComponent,
		ProductOptionSelectorComponent,
		ResponsiveImageComponent,
		TranslatedStringsComponent,
		WithLineBreaksPipe,
		ParseIntPipe,
	],
	exports: [
		AutofocusDirective,
		BreadcrumbComponent,
		CommonModule,
		ContentPageComponent,
		FallbackProductImageDirective,
		FlexLayoutModule,
		FoldSuccessPipe,
		FormAsteriskDirective,
		FormsModule,
		GetTextPipe,
		InputTrimDirective,
		IsHiddenCategoryPipe,
		LinkRewriteDirective,
		LoadingSpinnerComponent,
		LoginFormComponent,
		MatButtonModule,
		MatTooltip,
		NumericInputComponent,
		PaginatorComponent,
		PasswordStrengthComponent,
		PrintFlexDirective,
		PrintLayoutAlignDirective,
		PrintLayoutDirective,
		ProductListThumbnailComponent,
		ProductOptionSelectorComponent,
		ReactiveFormsModule,
		ResponsiveImageComponent,
		RouterModule,
		TranslatedStringsComponent,
		ValidationFieldPipe,
		WithLineBreaksPipe,
		ParseIntPipe,
		ResponsiveImageDirective,
	],
	providers: [PrintBreakpointsProvider], // For FlexLayoutModule.
})
export class SharedModule {}
