import { AsyncPipe, JsonPipe, NgOptimizedImage } from '@angular/common';
import { Component, Input, Pipe, PipeTransform } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteOrigin } from '@angular/material/autocomplete';
import { Router, RouterModule } from '@angular/router';
import { searchProducts } from '@app/api/Search';
import { IconComponent } from '@app/shared/components/icon/icon.component';
import { DataLayer } from '@app/shared/services/data-layer.service';
import { GetTextPipe } from '@app/shared/utils/i18n.service';
import { getDebug } from '@app/shared/utils/util';
import { faEmptySet } from '@fortawesome/pro-regular-svg-icons/faEmptySet';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons/faMagnifyingGlass';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { Initialized, Kinds, Pending, fetchRemoteData$ } from '@granodigital/grano-remote-data';
import { merge } from 'rxjs';
import { filter, map } from 'rxjs/operators';

const MINIMUM_SEARCH_TERM_LENGTH = 2;
const MAX_SEARCH_RESULTS = 6;

/** Pipe to parse search results. */
@Pipe({ name: 'parseSearchResults', standalone: true })
export class ParseSearchResultsPipe implements PipeTransform {
	/** Pipe to parse search results. */
	transform(response: api.ProductSearchResponseDto): api.ProductDto[] {
		const body = response?.body;
		const hits = body?.hits?.total?.value ?? 0;
		const suggestions = body?.suggest?.product_suggest?.[0]?.options;
		let results: api.ProductDto[] = [];
		if (hits > 0) results = body?.hits?.hits?.map?.((hit) => hit._source!) ?? [];
		else if ((suggestions?.length ?? 0) > 0) results = suggestions!.map((hit) => hit._source!);
		return results;
	}
}

/** Search field component. */
@Component({
	standalone: true,
	imports: [
		ParseSearchResultsPipe,
		RouterModule,
		AsyncPipe,
		GetTextPipe,
		IconComponent,
		MatAutocompleteModule,
		ReactiveFormsModule,
		NgOptimizedImage,
		JsonPipe,
	],
	selector: 'g-search-field',
	templateUrl: './search-field.component.html',
	styleUrls: ['./search-field.component.scss'],
})
export class SearchFieldComponent {
	@Input() readonly autocompleteOrigin?: MatAutocompleteOrigin;
	@Input() readonly panelWidth?: string;
	readonly icons = { faMagnifyingGlass, faSpinner, faEmptySet };
	readonly Kinds = Kinds;
	readonly control = new FormControl('');
	private readonly debug = getDebug('SearchFieldComponent');
	/** Search query input filtered by length. */
	private readonly searchQuery$ = this.control.valueChanges.pipe(
		filter(
			(query): query is string =>
				typeof query === 'string' && (query?.length ?? 0) >= MINIMUM_SEARCH_TERM_LENGTH,
		),
	);
	/** Perform product search with given search input. */
	readonly results$ = merge(
		// Set state to initialized if search query is empty or too short.
		this.control.valueChanges.pipe(
			map((query) =>
				!query || query.length < MINIMUM_SEARCH_TERM_LENGTH ? new Initialized() : new Pending(),
			),
		),
		fetchRemoteData$([this.searchQuery$], (query) => {
			this.dataLayer.addSearch(query);
			return searchProducts(query, { limit: MAX_SEARCH_RESULTS });
		}),
	).pipe(this.debug.observe('results$'));

	constructor(
		private readonly dataLayer: DataLayer,
		public readonly router: Router,
	) {}
}
