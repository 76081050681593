/**
 * Overrides the name of a given function.
 * @param name - The name to set for the function.
 * @param fn - The function whose name is to be set.
 * @returns The function with the updated name.
 */
// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
export function setName<T extends Function>(name: string, fn: T): T {
	Object.defineProperty(fn, 'name', { value: name });
	return fn;
}
