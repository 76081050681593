import { ZoomableImageComponent } from '../zoomable-image/zoomable-image.component';
import { DecimalPipe, NgIf, NgOptimizedImage } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { getPriceForProduct } from '@app/api/action/Price';
import { RootReducer, Store } from '@app/app.reducers';
import {
	GetProductQuantityPipe,
	GetQuantityUnitPipe,
	IsPrintfileAvailablePipe,
} from '@app/checkout/modules/checkout-shared/pipes/product.pipes';
import {
	addProduct,
	getAddedProduct,
	resetAddedProduct,
} from '@app/checkout/modules/checkout-shared/reducers/checkout.reducer';
import { ProductSource } from '@app/product/product-types';
import {
	ProductMediaItem,
	ProductPriceRequest,
	SelectedOptions,
	getPrice,
	getProductsForOrder,
} from '@app/product/reducers/product.reducer';
import { ProductPreflightService } from '@app/product/services/product-preflight.service';
import {
	ProductWithId,
	camelCase,
	collectProductImages,
	getProductAssetUrl,
	hasApprovalWorkflow,
	hasUserProvidedPrintfile,
	isDownloadableProduct,
} from '@app/product/utils/product-utils';
import { popErrorToast, popSuccessToast } from '@app/shared/reducers/toast.reducer';
import { DataLayer } from '@app/shared/services/data-layer.service';
import { TranslatedService } from '@app/shared/services/translated.service';
import { UUID_REGEX, filterTruthy, firstTruthy } from '@app/shared/utils/util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '@src/environments/environment';
import { firstValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';

// TODO: This whole component should be refactored.

/** A component for displaying the order product list. */
@UntilDestroy()
@Component({
	standalone: true,
	selector: 'g-order-product-list',
	templateUrl: './order-product-list.component.html',
	styleUrls: ['./order-product-list.component.scss'],
	imports: [
		DecimalPipe,
		ZoomableImageComponent,
		FlexLayoutModule,
		NgIf,
		NgOptimizedImage,
		GetProductQuantityPipe,
		GetQuantityUnitPipe,
		IsPrintfileAvailablePipe,
	],
})
export class OrderProductListComponent implements OnInit, OnChanges {
	@Input({ required: true }) order!: api.OrderDto;
	@Input() showCopyToBasket = true;
	@Input() showPrices = true;
	products: api.OrderProductDto[] = [];
	productImages = new Map<string, ProductMediaItem[] | string[]>();
	isOrderStatusReady!: boolean;
	redirectToShoppingCart = this.router.navigate.bind(this.router, ['/checkout/cart']);
	isButtonColumnVisible = new Map<string, boolean>();
	isDownloadButtonVisible = new Map<string, boolean>();
	isCopyButtonVisible = new Map<string, boolean>();
	buttonColumnLayout = new Map<string, string>();
	stockLevels = new Map<number, number>();
	hasUserProvidedPrintfile = hasUserProvidedPrintfile;
	private existingProducts: ProductWithId[] = [];
	protected isOrderConfirmation!: boolean;
	private readonly productsForOrder$ = this.store.select(getProductsForOrder);
	private readonly window: Window = window;

	constructor(
		private readonly store: Store<RootReducer.State>,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly dataLayer: DataLayer,
		private readonly translated: TranslatedService,
		private readonly preflight: ProductPreflightService,
	) {}

	// TODO: Remove async/await from ngOnChanges.
	/**
	 * Lifecycle hook that is called when one or more input properties of the component change.
	 * @param changes An object containing the changed input properties.
	 * @returns A Promise that resolves when the method has completed.
	 */
	async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if (!changes.order || (changes.order.firstChange && !changes.order.currentValue)) return;
		if (this.isOrderConfirmation === undefined) {
			const url = await firstValueFrom(this.route.url);
			this.isOrderConfirmation = this.checkOrderConfirmation(url);
		}
		if (!this.isOrderConfirmation) {
			// When not on order confirmation page we must wait for product data
			this.stockLevels.clear();
			for (const product of await firstValueFrom(this.productsForOrder$.pipe(firstTruthy))) {
				if (product.type === 'stock') this.stockLevels.set(product.id!, product.stock_level ?? 0);
			}
		}
		this.products = [];
		this.productImages.clear();
		for (const orderProduct of this.order.products) {
			this.products.push(orderProduct);
			const images = collectProductImages(orderProduct);
			this.productImages.set(orderProduct.uuid, images);
			this.setButtonVisibility(orderProduct);
			this.setButtonColumnLayout(orderProduct);
		}
	}

	/**
	 * Initializes the component.
	 */
	ngOnInit(): void {
		this.productsForOrder$.pipe(filterTruthy, untilDestroyed(this)).subscribe((products) => {
			// Hide "Copy to basket" buttons until products have been fetched to prevent
			// blinking styling weirdness
			this.isOrderStatusReady = true;
			this.existingProducts = products as ProductWithId[];
		});
	}
	/**
	 * Checks if the product is an existing product.
	 */
	isExistingProduct(product: Pick<api.OrderProductDto, 'product'>): boolean {
		if (this.isOrderConfirmation) return true;
		if (!product || !Number.isFinite(product?.product?.id)) return false;
		if (!this.existingProducts?.find((existing) => existing.id === product.product?.id)) return false;
		return true;
	}
	/**
	 * Downloads the asset associated with the order product.
	 */
	downloadProductAsset(orderProduct: Pick<api.OrderProductDto, 'printfile' | 'product'>): void {
		const url = getProductAssetUrl(orderProduct);
		if (!url) {
			this.store.dispatch(popErrorToast({ title: this.translated.string('toast.downloadAssetError') }));
			return;
		}
		this.window.open(url, '_blank');
	}

	/**
	 * Copies the order product to the basket.
	 */
	copyToBasket(orderProduct: api.OrderProductDto): void {
		this.store.dispatch(resetAddedProduct());
		const {
			product,
			options: { selected_options = {} },
			printfile,
			work_title = '',
			product_flow_data,
		} = orderProduct;

		const shouldQueuePreflight = this.preflight.shouldQueuePreflight(printfile, true, selected_options);

		// Get existing product so it has up to date data like stock levels etc
		const currentProduct = this.existingProducts?.find((existing) => existing.id === product?.id);
		// istanbul ignore if
		if (!currentProduct) throw new Error('Product not found');
		// Fetch up to date price
		if (currentProduct.id)
			this.store.dispatch(
				getPriceForProduct(
					currentProduct?.id,
					{ body: selected_options },
					{ productId: currentProduct?.id },
				),
			);
		// Wait for price information
		this.store
			.select(getPrice(currentProduct.id))
			.pipe(first((priceState?: ProductPriceRequest) => !!priceState && !priceState?.isFetching))
			.subscribe((currentPriceState) => {
				if (currentPriceState!.isError) {
					// Catch price calculation errors
					this.store.dispatch(popErrorToast({ title: this.translated.string('toast.priceCalcFailed') }));
					return;
				}

				this.addToCart(
					currentProduct,
					selected_options,
					printfile,
					work_title,
					currentPriceState!.price!,
					product_flow_data,
					shouldQueuePreflight,
				);
			});

		// Wait for the product to be added to the cart.
		// This is needed to get the cart item id for preflight.
		this.store
			.select(getAddedProduct)
			.pipe(firstTruthy)
			.subscribe((cartItem) => {
				// Trigger preflight when needed, show toast and redirect to product edit page
				if (shouldQueuePreflight)
					this.preflight.queuePreflight(
						cartItem.id,
						cartItem.product,
						printfile,
						selected_options,
						cartItem.price!,
					);
				this.store.dispatch(
					popSuccessToast({
						title: $localize`:@@ToastProductCopiedToCart:Product copied to shopping cart`,
					}),
				);
				void this.router.navigate(['/checkout/cart']);
			});
	}
	/**
	 * Checks if the printfile error should be shown for the given product.
	 * @returns True if the printfile error should be shown, false otherwise.
	 */
	showPrintfileError(orderProduct: api.OrderProductDto): boolean {
		// don't show printfile error on order confirmation page
		if (this.isOrderConfirmation) return false;
		// checks if the product is a stock or POD product
		// stocks and PODs don't have printfiles that expire so NEVER ERROR
		if (orderProduct.product && !hasUserProvidedPrintfile(orderProduct.product)) return false;
		// if printfile has expired, show error
		if (this.hasProductPrintfileExpired(orderProduct)) return true;
		return false;
	}
	/**
	 * Checks if the expiration date is valid.
	 */
	private hasProductPrintfileExpired(product: api.OrderProductDto): boolean {
		// If the product has no printfile, return true
		if (!product?.printfile?.expires_at) return true;
		// If the product has a printfile and it has expired, return true.
		return new Date(product?.printfile?.expires_at).valueOf() < Date.now();
	}
	/**
	 * Sets the visibility of buttons based on the order confirmation status.
	 */
	private setButtonVisibility(product: api.OrderProductDto) {
		if (this.isOrderConfirmation) {
			this.isCopyButtonVisible.set(product.uuid, false);
			this.isDownloadButtonVisible.set(product.uuid, false);
			this.isButtonColumnVisible.set(product.uuid, false);
			return;
		}

		if (this.showPrintfileError(product)) {
			this.isCopyButtonVisible.set(product.uuid, false);
			return;
		}

		const earliestReorderDate = new Date();
		earliestReorderDate.setDate(earliestReorderDate.getDate() - environment.maxReorderTimeInDays);
		const orderCreated = new Date(this.order.created_at || new Date());
		const isDownloadAllowed = this.isDownloadAllowed(product);

		this.isCopyButtonVisible.set(
			product.uuid,
			this.showCopyToBasket && (!product.printfile?.key || orderCreated > earliestReorderDate),
		);

		// TODO: After a year from this commit, replace the hardcoded 365-day limit with printfile information.
		// If the printfile is missing, it means it has expired, eliminating the need for the hardcoded limit.
		// this.isCopyButtonVisible.set(
		// 	product.uuid,
		// 	this.showCopyToBasket &&
		// 		// TODO: Make order item & cart item types compatible.
		// 		isPrintfileAvailable({
		// 			...product,
		// 			options: { ...product.options, printfile: product.printfile },
		// 		}),
		// );
		this.isDownloadButtonVisible.set(product.uuid, isDownloadAllowed);
		this.isButtonColumnVisible.set(product.uuid, this.showCopyToBasket || isDownloadAllowed);
	}
	/**
	 * Sets the layout of the button column based on the visibility of the buttons.
	 */
	private setButtonColumnLayout(orderProduct: api.OrderProductDto) {
		this.buttonColumnLayout.set(
			orderProduct.uuid,
			this.isButtonColumnVisible.get(orderProduct.uuid) ? '80px 2fr 1fr 1fr 3fr' : '80px 5fr 1fr 1fr',
		);
	}
	/**
	 * Checks if the download is allowed for the given order product.
	 */
	private isDownloadAllowed(orderProduct: api.OrderProductDto): boolean {
		return (
			!!orderProduct?.product &&
			isDownloadableProduct(orderProduct.product) &&
			(!hasApprovalWorkflow(orderProduct.product) || orderProduct?.approval_status === 'Approved')
		);
	}

	/** Adds the product to the cart. */
	private addToCart(
		product: api.CartItemProductDto,
		selectedOptions: SelectedOptions,
		printfile: api.OrderProductPrintfileDto | undefined,
		workTitle: string,
		price: api.ProductPriceDto,
		orderProductProductFlowData: api.OrderProductProductFlowDataDto,
		isProcessing: boolean,
	) {
		const id = crypto.randomUUID();

		const cartItemProductFlowData = Object.fromEntries(
			Object.entries(orderProductProductFlowData).map(([key, value]) => [camelCase(key), value]),
		) as api.CartItemProductFlowDataDto;

		this.store.dispatch(
			addProduct(
				id,
				product,
				{ workTitle, selectedOptions, printfile },
				price,
				{
					...cartItemProductFlowData,
					productSource: ProductSource.PreviousOrder,
				},
				isProcessing,
			),
		);
		this.dataLayer.addProductToCart({ id, product, price, options: {} });
	}

	/** Checks if the order confirmation page is being viewed. */
	private checkOrderConfirmation(url: UrlSegment[]): boolean {
		if (url?.[0]?.path !== 'order-confirmed') return false;
		return !!UUID_REGEX.test(url?.[1]?.path ?? '');
	}
}
