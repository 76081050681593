import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { getCart, getOrderMessage } from '@app/api/Cxml';
import { RootReducer, Store, hideGlobalSpinner, showGlobalSpinner } from '@app/app.reducers';
import {
	getCxmlUserInfo,
	getIsCxmlSession,
	setCartItems,
	setCxmlSession,
	setCxmlUserInfo,
	updateAddress,
	updateShippingMethod,
} from '@app/checkout/modules/checkout-shared/reducers/checkout.reducer';
import { ProductListDto } from '@app/checkout/modules/checkout-shared/reducers/persisted-list.reducer';
import { Dialog, open } from '@app/dialog/reducers/dialog.reducer';
import {
	getIsCxmlOrderRequestHandlingEnabled,
	getIsCxmlOrderShippingAddressEnabled,
} from '@app/shared/reducers/storefront.reducer';
import { popErrorToast, popStickyErrorToast } from '@app/shared/reducers/toast.reducer';
import { CognitoService } from '@app/shared/services/cognito.service';
import { getDebug, hasProp } from '@app/shared/utils/util';
import { AddressFormModel } from '@app/user/reducers/address-book.reducer';
import { getCxmlToken, setCxmlToken } from '@app/user/reducers/auth.reducer';
import { combineLatest, firstValueFrom } from 'rxjs';
import { filter, first, map, shareReplay, switchMap } from 'rxjs/operators';

export enum CxmlSessionType {
	/**
	 * Session which does not support entering shipping address. Cart is uploaded in the shopping cart view and
	 * user cannot enter shipping details.
	 */
	PunchoutWithoutCheckout = 'PunchoutWithoutCheckout',
	/**
	 * Session which supports entering shipping address. Cart is uploaded after a shortened checkout flow, where
	 * the user enters shipping details.
	 *
	 * When order request handling is enabled, shipping address is always supported. Otherwise it has to be
	 * specifically enabled from the admin with the "Ask for shipping address"
	 */
	PunchoutWithCheckout = 'PunchoutWithCheckout',
	/**
	 * Session where a previously made cart is ordered manually through the checkout flow.
	 */
	ManualPurchaseOrder = 'ManualPurchaseOrder',
}

/** CXML service for handling CXML sessions. */
@Injectable({ providedIn: 'root' })
export class CxmlService {
	readonly SessionType = CxmlSessionType;
	readonly sessionStorage = window.sessionStorage;
	private readonly debug = getDebug('CxmlService');

	readonly punchoutCartId$ = this.route.queryParamMap.pipe(
		first(),
		map<ParamMap, number>((query) => Number.parseInt(query.get('punchout') || '')),
	);
	readonly isCxmlSession$ = this.store.select(getIsCxmlSession);
	readonly cxmlSessionType$ = combineLatest([
		this.isCxmlSession$,
		this.punchoutCartId$,
		this.store.select(getIsCxmlOrderRequestHandlingEnabled),
		this.store.select(getIsCxmlOrderShippingAddressEnabled),
	]).pipe(
		map(
			([
				isCxmlSession,
				cartId,
				isCxmlOrderRequestHandlingEnabled,
				isCxmlOrderShippingAddressEnabled,
			]) => {
				this.debug('checking cxmlSessionType$', {
					isCxmlSession,
					cartId,
					isCxmlOrderRequestHandlingEnabled,
					isCxmlOrderShippingAddressEnabled,
				});
				if (!isCxmlSession) return;
				if (Number.isFinite(cartId)) return CxmlSessionType.ManualPurchaseOrder;
				if (isCxmlOrderShippingAddressEnabled || isCxmlOrderRequestHandlingEnabled)
					return CxmlSessionType.PunchoutWithCheckout;
				return CxmlSessionType.PunchoutWithoutCheckout;
			},
		),
		this.debug.observe('cxmlSessionType$'),
		shareReplay(1),
	);

	readonly punchOutUserInfo$ = combineLatest([
		this.isCxmlSession$,
		this.store.select(getCxmlUserInfo),
	]).pipe(
		map(([isCxmlSession, orderer]) => {
			if (!isCxmlSession) return undefined;
			return orderer;
		}),
	);

	constructor(
		private readonly store: Store<RootReducer.State>,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly cognito: CognitoService,
	) {}

	async sendCxmlCart(
		cartItems: api.CartItemWithProductDto[],
		shippingAddress?: api.AddressDataDto,
		shippingMethod?: string,
	): Promise<void> {
		this.store.dispatch(showGlobalSpinner());
		let trace = '';
		try {
			const cxmlToken = await firstValueFrom(this.store.select(getCxmlToken));
			const res = await getOrderMessage({
				session_token: cxmlToken,
				items: cartItems,
				shipping_address: shippingAddress,
				shipping_method: shippingMethod,
			});
			const { endpoint, payload } = res.data;
			if (!endpoint || !payload) {
				trace =
					hasProp(res.data, 'body') &&
					hasProp(res.data.body, 'trace') &&
					typeof res.data.body.trace === 'string' &&
					res.data.body.trace;
				throw new Error('Failed punchout getOrderMessage');
			}
			// Reset cXML and Cognito sessions.
			this.sessionStorage.removeItem('cxml_token');
			this.store.dispatch(setCxmlToken(null));
			await this.cognito.logout();
			// Create a form and submit it to send the user back to the cXML system.
			const form = document.createElement('form');
			form.method = 'POST';
			form.action = endpoint;
			const payloadInput = document.createElement('input');
			payloadInput.hidden = true;
			payloadInput.name = 'cxml-base64';
			payloadInput.value = payload;
			form.appendChild(payloadInput);
			document.body.appendChild(form);
			form.submit();
		} catch (err) {
			this.store.dispatch(hideGlobalSpinner());
			const msg = $localize`:@@SendCxmlCartFailed:Failed to send punch out cart!`;
			const title = `${msg}${trace ? ` (${trace})` : ''}`;
			this.store.dispatch(popStickyErrorToast({ title }));
			throw err;
		}
	}

	async initCxmlCart(hasCartItems: boolean): Promise<void> {
		const confirmOverride = async () =>
			new Promise<ProductListDto>((resolve, reject) =>
				this.store.dispatch(
					open(Dialog.CONFIRM, {
						title: $localize`:@@ProductSummaryListConfirmCxmlOverrideBody:
							Do you want to override your cart contents with punch out cart?`,
						acceptLabel: $localize`:@@ProductSummaryListConfirmCxmlOverrideAccept:
							Override`,
						accept: resolve,
						reject: () => reject(new Error('canceled')),
					}),
				),
			);

		try {
			await this.punchoutCartId$
				.pipe(
					filter((cartId) => Number.isFinite(cartId)),
					switchMap((cartId) => getCart(cartId)),
					map((res) => {
						if (res.error || !res.data) throw new Error('Failed to load punch out cart');
						return res.data;
					}),
					switchMap(async (list) => (hasCartItems && (await confirmOverride()) && list) || list),
					switchMap(async (list) => {
						if (
							list &&
							hasProp(list.metadata, 'cxml_session_id') &&
							typeof list.metadata.cxml_session_id === 'number'
						) {
							this.store.dispatch(setCxmlSession(list.metadata.cxml_session_id));
						}

						this.store.dispatch(setCartItems(list.products));

						if (list.metadata.cxml_shipping_address) {
							this.store.dispatch(
								updateAddress('shipping', list.metadata.cxml_shipping_address as AddressFormModel),
							);
						}

						if (list.metadata.cxml_shipping_method) {
							this.store.dispatch(updateShippingMethod(list.metadata.cxml_shipping_method));
						}

						if (list.metadata.cxml_user_info) {
							this.store.dispatch(setCxmlUserInfo(list.metadata.cxml_user_info));
						}

						return list;
					}),
				)
				.toPromise();
		} catch (error: unknown) {
			if (error instanceof Error && error.message !== 'canceled') {
				this.store.dispatch(
					popErrorToast({
						title: $localize`:@@ProductSummaryListToastCxmlCartLoadFailed:
							Failed to load PunchOut cart!`,
					}),
				);
				throw error;
			}
			// Remove punchout query parameter if override was canceled.
			void this.router.navigate([], {
				queryParams: { punchout: null },
				queryParamsHandling: 'merge',
			});
		}
	}
}
